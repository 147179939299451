import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Tooltip} from "@material-ui/core";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from '@material-ui/core/styles';
import DragDropAreaStyle from './DragDropArea';
import CircularProgress from "@material-ui/core/CircularProgress";
import {formatSizeInBytes} from "../../utils/i18n";

export const DragDropArea = ({
    onFilesSelected,
    fileUploadProgress,
    resetFiles
}) => {
    const useStyles = makeStyles((theme) => DragDropAreaStyle(theme));
    const classes = useStyles();
    const inputFileRef = React.useRef();
    const [files, setFiles] = React.useState([]);
    const [uploadProgress, setUploadProgress] = React.useState([]);

    useEffect(() => {
        onFilesSelected(files);
    }, [files, onFilesSelected]);

    useEffect(
        () => {
            if (resetFiles) {
                setFiles([]);
            }
        },
        [resetFiles]
    );

    useEffect(
        () => {
            setUploadProgress(fileUploadProgress);
        },
        [fileUploadProgress]
    );

    const updateFileList = (fileList) => {
        if (fileList && fileList.length > 0) {
            const newFiles = Array.from(fileList).map((f) => {
                return {
                    file: f,
                    status: "",
                    progress: -1
                }
            });
            setFiles([...files, ...newFiles]);
        }
    }

    const handleAddAttachmentClick = (evt) => {
        inputFileRef.current.click();
    }

    const handleDrop = (evt) => {
        evt.preventDefault();
        updateFileList(evt.dataTransfer.files);
    }

    const handleRemoveFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    const handleDropOver = (evt) => {
        evt.preventDefault();
    }

    const handleFileChange = (evt) => {
        evt.preventDefault();
        updateFileList(evt.target.files);
    }

    return (
        <section className={`${classes.dragDrop}${files.length ? ' active' : ''}`}
                 onDrop={handleDrop}
                 onDragOver={handleDropOver}
        >
            <Tooltip title="Drag and drop here one or more files that you want to attach to the Ticket">
                <List className={classes.fileList}>
                    {files.map((file, index) => (
                        <ListItem className={classes.fileItem} key={index}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText className={classes.fileInfo}>
                                <p>{file?.file?.name ?? ''}</p>
                                <p>{file?.file?.type ?? ''}</p>
                                <p>{formatSizeInBytes(file?.file?.size ?? 0)}</p>
                            </ListItemText>
                            <ListItemSecondaryAction className={classes.fileActions}>
                                {file?.status === 'uploading' ? (
                                    <CircularProgress />
                                ) : (
                                    file?.status === 'error' ? (
                                        <Tooltip title="Could not upload the attachment">
                                            <IconButton onClick={() => handleRemoveFile(index)}>
                                                <CancelIcon />
                                            </IconButton>
                                        </Tooltip>
                                        ) : (
                                            <IconButton onClick={() => handleRemoveFile(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Tooltip>
            <input
                type="file"
                hidden
                id="browse"
                ref={inputFileRef}
                onChange={handleFileChange}
                accept=""
                multiple
                title=""
                value=""
            />
            <Button onClick={handleAddAttachmentClick}>
                <AddIcon />
                Add attachment
            </Button>
        </section>
    )
}

DragDropArea.propTypes = {
    onFilesSelected: PropTypes.func,
    attachments: PropTypes.array,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(DragDropArea);
