import React from "react";
import PropTypes from "prop-types";
import {Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { TransformWrapper, TransformComponent, useTransformInit } from "react-zoom-pan-pinch";
import useStyles from "./VideoPlayer.js";

const VideoPlayer = ({
    videoUrl,
    videoTitle,
    open,
    onClose,
    loadingPoster,
    showVideoDownload,
    showVideoSaveButtons,
    saveMessageShow,
    saveMessage,
}) => {
    const minVideoZoomLevel = 1.0;
    const maxVideoZoomLevel = 10.0;
    const videoZoomStep = 0.5;
    const classes = useStyles();

    const videoRef = React.useRef(null);
    const player = React.useRef(null);
    const playerState = React.useRef(null);

    const handleClose = (evt) => {
        onClose && onClose();
    }

    const PlayerChild = ({zoomIn, zoomOut, setTransform, resetTransform, ...rest}) => {
        useTransformInit(
            ({state, instance}) => {
                player.current = instance;
                playerState.current = state;

                console.log('PlayerState = %o', playerState.current);
            }
        );

        const handlePanningStart = (evt) => {
            console.log('Panning Start = %o', evt);
        }

        const handlePanningStop = (evt) => {
            console.log('Panning Stop = %o', evt);
            player.current.setTransformState(evt.state.scale, evt.state.x, evt.state.y);
        }

        return (
            <div className={classes.previewDialog}>
                <div className={classes.zoomBar}>
                    <IconButton aria-label="Zoom In"
                                onClick={(evt) => zoomIn()}
                                disabled={(playerState.scale ?? 0) === maxVideoZoomLevel}
                    >
                        <ZoomInIcon />
                    </IconButton>
                    <IconButton aria-label="Zoom Out"
                                onClick={(evt) => zoomOut()}
                                disabled={(playerState.scale ?? 0) === minVideoZoomLevel}
                    >
                        <ZoomOutIcon />
                    </IconButton>
                    <IconButton aria-label="Reset Zom"
                                onClick={(evt) => resetTransform()}
                                disabled={(playerState.scale ?? 0) === minVideoZoomLevel}
                    >
                        <RotateLeftIcon />
                    </IconButton>
                </div>
                <TransformComponent
                    onPanningStart={handlePanningStart}
                    onPanningStop={handlePanningStop}
                >
                    <video
                        ref={videoRef}
                        className={classes.videoModal}
                        style={{position: 'relative', display: 'block', width: '100%', height: '100%'}}
                        poster={loadingPoster ?? ''}
                        width="750"
                        height="500"
                        controls
                        autoPlay
                    >
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                </TransformComponent>
            </div>
        );
    }

    const Player = () => {



        return (
            <div className={classes.previewDialog}>
                <TransformWrapper
                    initialScale={1}
                    limitToBounds={false}
                    centerZoomedOut={true}
                    minScale={minVideoZoomLevel}
                    maxScale={maxVideoZoomLevel}
                    panning={{ excluded: ['input'] }}
                    doubleClick={{disabled: true}}
                >
                    {(params) => <PlayerChild {...params} className={classes.previewDialog}/>}
                </TransformWrapper>
            </div>
        );
    }

    return (
        <Dialog onClose={handleClose}
                open={open}
                fullWidth
                className={classes.videoDialog}
        >
            <DialogTitle className={classes.previewDialogTitle}>
                Screen Recording {videoTitle}
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.videoPlayer} >
                <Player className={classes.previewDialog}/>
                <div className={classes.dialogFooter}>
                    {showVideoDownload()}
                    {showVideoSaveButtons()}
                    {saveMessageShow && (<p className={classes.success}>{saveMessage}</p>)}
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default VideoPlayer;
