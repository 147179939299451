export const AlertPopup = (theme) => ({
    alert: {
        position: 'fixed',
        left: 'calc(100vw - 100% - 2em)',
        top: 'calc(100vh - 6rem)',
        zIndex: '100000',
        isolation: 'isolate',
        padding: '1em',
        borderWidth: '1px',
        borderStyle: 'solid',
        boxShadow: '2px 2px 6px 0 rgba(32, 32, 32, 0.5)',
        width: 'auto',
        transform: 'translateX(calc(100vw - 100% - 1rem))',

        '& [severity="warning"]': {
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.warning.dark,
            borderColor: theme.palette.warning.dark,
        },
    },
});

export default AlertPopup;
