import React, { useEffect, useState } from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import { connect } from 'react-redux';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import moment from 'moment';
import StyledLink from "../../components/common/StyledLink";
import {
    handleShowReviewerButtons,
    fetchAssignments,
    fetchUserData,
    fetchTotalAssignments,
    fetchScoreList,
    reset,
    fetchAssignmentsCount,
} from '../../redux/sims/actions';
import { fetchHelpdeskTickets } from '../../redux/helpdesk/actions';
import VisibilityIcon from "@material-ui/icons/Visibility";
import DashboardPermissions from '../../permissions/dashboard';
import SimsPermissions from '../../permissions/sims';
import { permissionCheck } from '../../utils/permissionCheck';
import DashboardStyle from "../dashboard/Dashboard";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import Pagination from "@material-ui/lab/Pagination";
import {
    Tooltip,
    Grid,
    Button,
    Paper,
} from "@material-ui/core";
import HelpRequestTable from "../../components/helpdesk/HelpRequestTable.jsx";
import HelpdeskData from "../../components/helpdesk/HelpdeskData.jsx";

const StyledTableRow = withStyles((theme) => ({
    root: {
    },
}))(TableRow);

const Helpdesk = ({
    assignments,
    fetchAssignments,
    fetchHelpdeskTickets,
    unopenedPendingAssignments,
    permissions,
    reset,
    fetchAssignmentsCount,
    countData,
    userData,
    helpdeskTickets,
    helpdeskStats,
    total,
}) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user._id;
    const useStyles = makeStyles((theme) => DashboardStyle(theme));
    const classes = useStyles();

    const [value, setValue] = React.useState('help');
    const [textSearch, setTextSearch] = useState('');
    const [text, setText] = React.useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const [tickets, setTickets] = useState([]);
    const [totals, setTotals] = useState({});
    const [permission, setPermission] = useState(false);
    const [viewPermission, setViewPermission] = useState('');
    const [userPermission, setUserPermission] = useState(false);
    const [order, setOrder] = React.useState(null);
    const [orderBy, setOrderBy] = React.useState(null);
    const [page, setPage] = useState(1);
    const [pageTitle, setPageTitle] = React.useState('Help Requests');
    const [searchPlaceholder, setSearchPlaceholder] = React.useState('Search Assignments');
    const [ticketFilters, setTicketFilters] = React.useState({
        unassigned: true,
        archived: false,
        resolved: true,
        rejected: true,
        pending: true,
        inProgress: true,
    });

    useEffect(() => {
        if (
            permissionCheck(
                DashboardPermissions.user_account.view.task,
                DashboardPermissions.user_account.view.type,
            )
        ) {
            fetchUserData();
        }
    }, [permissions]);

    useEffect(() => {
        if (viewPermission) {
            if (!permission) {
                // Check if all assignment permission is denied, so check for ready review option
                if (
                    !permissionCheck(
                        DashboardPermissions.all_assignments.view.task,
                        DashboardPermissions.all_assignments.view.type,
                    )
                ) {
                    setValue('help');
                    setSearchPlaceholder('Search helpdesk tickets');
                    setPage(1);
                    fetchHelpdeskTickets(pageNumber, textSearch, orderBy, order, ticketFilters);
                }
            } else {
                setValue('readyForReview');
                setSearchPlaceholder('Search assignments');
                setPage(1);
                fetchAssignments(pageNumber, textSearch, false, 'PENDING_REVIEW', orderBy, order, 0);
            }
            fetchAssignmentsCount();
        }
        // eslint-disable-next-line max-len
    }, [fetchAssignments, fetchHelpdeskTickets, permission, viewPermission]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (viewPermission && permission) {
            if (value === 'help' && permission) {
                setPage(1);
                setSearchPlaceholder('Search helpdesk tickets');
                fetchHelpdeskTickets(pageNumber, textSearch, orderBy, order, ticketFilters);
            } else {
                const tab = 'PENDING_REVIEW';
                setPage(1);
                setSearchPlaceholder('Search assignments');
                fetchAssignments(pageNumber, textSearch, false, tab, orderBy, order, 0);
            }
        }
        // eslint-disable-next-line max-len
    }, [pageNumber, textSearch, fetchAssignments, fetchHelpdeskTickets, permission, viewPermission]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setTickets(helpdeskTickets);
        setTotals(helpdeskStats);
    }, [helpdeskTickets, helpdeskStats]);

    useEffect(
        () => {
            const storedTickets = JSON.parse(localStorage.getItem('ticketFilters'));
            if (storedTickets) {
                setTicketFilters(storedTickets);
            }
        },
        []
    );

    const onTicketFiltersChange = async(newFilters) => {
        setTicketFilters(newFilters);
        localStorage.setItem('ticketFilters', JSON.stringify(newFilters));
        await fetchHelpdeskTickets(page, textSearch, orderBy, order, newFilters);
    }

    const onTabChange = async (event) => {
        //setAssign([]);
        setPageNumber(0);
        setValue(event.currentTarget.value);
        reset();
        setPage(1);

        if (event.currentTarget.value === 'help') {
            await fetchHelpdeskTickets(pageNumber, textSearch, orderBy, order, ticketFilters);
            setPageTitle('Help Requests');
            setSearchPlaceholder('Search helpdesk tickets');
        } else {
            fetchAssignments(pageNumber, textSearch, false, 'PENDING_REVIEW', orderBy, order, 0);
            setPageTitle('Ready for Review Assignments');
            setSearchPlaceholder('Search assignments');
        }
    };

    const handleClick = () => {
        alert('Click');
    }

    const search = (e) => {
        if (e.key === 'Enter' || (e.type === 'mousedown')) {
            setPageNumber(0);
            setTextSearch(text);
        }
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        return isAsc ? 'desc' : 'asc';
    };

    const sortProperties = (property) => {
        const ord = handleRequestSort(property);
        const ordBy = property;
        setPageNumber(0);
        setOrderBy(property);
        setPage(1);
        if (value === 'readyForReview') {
            fetchAssignments(pageNumber, textSearch, false, 'PENDING_REVIEW', ordBy, ord, 0);
        } else {
            fetchHelpdeskTickets(pageNumber, textSearch, ordBy, ord, ticketFilters);
        }
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        const skip = (newPage - 1) * 10;
        if (value === 'readyForReview') {
            fetchAssignments(pageNumber, textSearch, false, 'PENDING_REVIEW', orderBy, order, skip);
        } else {
            fetchHelpdeskTickets(pageNumber, textSearch, orderBy, order, ticketFilters);
        }
    };

    const onTicketsChange = async (ticket, action) => {
        switch (action) {
            case 'close':
            case 'delete':
                fetchHelpdeskTickets(pageNumber, textSearch, orderBy, order, ticketFilters);
                break;
        }
    }

    const showAction = (row, index) => {
        return (
            <TableCell key={`actions_${row.id}`}>
                <div className={classes.built}>
                    {row.status === "PENDING REVIEW" && (
                        <StyledLink
                            to={{
                                pathname: "/sims/takesim",
                                search: `?levelId=${row.sim_level_id}&assignment_id=${row.assignment_id}&sim_id=${row.sim_id}&user_id=${row.assigned_to_id}&order=${row.sim_level_order}`,
                                state: {
                                    viewResult: true,
                                    // assignment: assignmentData,handleShowReviewerButtons
                                },
                            }}
                        >
                            <Tooltip title={"Review"} placement="top">
                                <VisibilityIcon
                                    onClick={() => handleShowReviewerButtons({payload: true})}
                                    className={classes.viewIcon}
                                    fontSize="small"
                                />
                            </Tooltip>
                        </StyledLink>
                    )}
                </div>
            </TableCell>
        );
    };

    if (
        permissionCheck(
            DashboardPermissions.all_assignments.view.task,
            DashboardPermissions.all_assignments.view.type,
        ) !== undefined
        && permissionCheck(
            DashboardPermissions.all_assignments.view.task,
            DashboardPermissions.all_assignments.view.type,
        )
        && !permission
    ) {
        setPermission(true);
    }
    if (
        permissionCheck(
            SimsPermissions.assignment.view.task,
            SimsPermissions.assignment.view.type,
        ) !== undefined
        && !viewPermission && (permissionCheck(
                SimsPermissions.assignment.view.task,
                SimsPermissions.assignment.view.type,
            )
            || permissionCheck(
                DashboardPermissions.review_assignments.view.task,
                DashboardPermissions.review_assignments.view.type,
            )
            || permissionCheck(
                DashboardPermissions.all_assignments.view.task,
                DashboardPermissions.all_assignments.view.type,
            ))
    ) {
        setViewPermission(
            permissionCheck(
                SimsPermissions.assignment.view.task,
                SimsPermissions.assignment.view.type,
            )
            || permissionCheck(
                DashboardPermissions.review_assignments.view.task,
                DashboardPermissions.review_assignments.view.type,
            )
            || permissionCheck(
                DashboardPermissions.all_assignments.view.task,
                DashboardPermissions.all_assignments.view.type,
            ),
        );
    }
    if (
        (permissionCheck(
                DashboardPermissions.company_name.view.task,
                DashboardPermissions.company_name.view.type,
            )
            || permissionCheck(
                DashboardPermissions.user_account.view.task,
                DashboardPermissions.user_account.view.type,
            )
            || permissionCheck(
                DashboardPermissions.user_name.view.task,
                DashboardPermissions.user_name.view.type,
            ))
        && !userPermission
    ) {
        setUserPermission(true);
    }

    return (
        <div>
            {(localStorage.getItem('backToDashboard') && !viewPermission && !userPermission) ? (
                <h1 style={{ textAlign: 'center' }}>No Assignments</h1>
            ) : (
                <Grid container spacing={2}>
                    <Grid item className={classes.grid3}>
                        <HelpdeskData
                            ticketData={totals}
                            countData={countData}
                            classes={classes}
                            ticketFilters={ticketFilters}
                            setTicketFilters={onTicketFiltersChange}
                        />
                    </Grid>
                    <Grid item className={classes.grid9}>
                        {!permissionCheck(
                            DashboardPermissions.all_assignments.view.task,
                            DashboardPermissions.all_assignments.view.type,
                        )
                        && !permissionCheck(
                            DashboardPermissions.review_assignments.view.task,
                            DashboardPermissions.review_assignments.view.type,
                        )
                        && !permissionCheck(
                            SimsPermissions.assignment.view.task,
                            SimsPermissions.assignment.view.type,
                        ) ? (
                            <h2 style={{ textAlign: 'center' }}>
                                No Permission For View Assignments, Please Contact Your
                                Administrative!
                            </h2>
                        ) : (
                            <Paper elevation={0} className={classes.paper}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            className={classes.title}
                                            variant="h6"
                                            id="tableTitle"
                                            component="div"
                                        >
                                            {pageTitle}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <List
                                            className={classes.padding}
                                            style={{ float: 'right' }}
                                        >
                                            <ListItem>
                                                <div className={classes.search}>
                                                    <InputBase
                                                        value={text}
                                                        placeholder={searchPlaceholder}
                                                        className={classes.inputSearch}
                                                        inputProps={{ 'aria-label': 'search' }}
                                                        onChange={(e) => setText(e.target.value)}
                                                        onKeyDown={(e) => search(e)}
                                                    />
                                                    <div className={classes.searchBtn}>
                                                        <Button onMouseDown={(e) => search(e)}><SearchIcon /></Button>
                                                    </div>
                                                </div>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="flex-start"
                                    className={classes.tabs}
                                >
                                    <Grid item xs={4} sm={2}>
                                        <Button
                                            variant="contained"
                                            value="help"
                                            onClick={onTabChange}
                                            className={value === 'help' ? classes.active : classes.inActive}
                                        >
                                            Help Requests ({tickets.length})
                                            {totals?.unassigned > 0 ? (
                                                <Badge
                                                    className={classes.badge}
                                                    badgeContent={totals?.unassigned}
                                                    max={999}
                                                    showZero
                                                    overlap="circular"
                                                    style={{"& span": {backgroundColor: "red"}, color: "white"}}
                                                />
                                            )  : ''}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <Button
                                            variant="contained"
                                            value="readyForReview"
                                            onClick={onTabChange}
                                            className={value === 'readyForReview' ? classes.active : classes.inActive}
                                        >
                                            Ready For Review  ({countData.readyForReviewCount})
                                            {unopenedPendingAssignments > 0 ? (
                                                <Badge
                                                    className={classes.badge}
                                                    badgeContent={unopenedPendingAssignments}
                                                    max={999}
                                                    showZero
                                                />
                                            )  : ''
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid container className={classes.container}>
                                    <Grid item xs={12} className={classes.gridWidth}>
                                        <TableContainer className={classes.tableContainer}>
                                            {value === 'help' ? (
                                                <HelpRequestTable tickets={tickets}
                                                                  classes={classes}
                                                                  onSort={sortProperties}
                                                                  orderBy={orderBy}
                                                                  order={order}
                                                                  onTicketsChange={onTicketsChange}
                                                />
                                            ) : (
                                                <Table aria-label="assignment table" className={classes.table}>
                                                    <TableHead className={classes.thead}>
                                                        <TableRow>
                                                            <TableCell className={classes.heading} sortDirection={orderBy === 'assigned_to' ? order : false} >
                                                                <TableSortLabel
                                                                    direction={orderBy === 'assigned_to' ? order : 'asc'}
                                                                    active
                                                                    // ={orderBy === 'assigned_to'}
                                                                    IconComponent={ArrowDropDownIcon}
                                                                    onClick={() => sortProperties('assigned_to')}
                                                                >
                                                                    ASSIGNED TO
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            <TableCell className={classes.heading} sortDirection={orderBy === 'tool_type' ? order : false}>
                                                                <TableSortLabel
                                                                    direction={orderBy === 'tool_type' ? order : 'asc'}
                                                                    active
                                                                    // ={orderBy === 'tool_type'}
                                                                    IconComponent={ArrowDropDownIcon}
                                                                    onClick={() => sortProperties('tool_type')}
                                                                >
                                                                    TOOL TYPE
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            <TableCell className={classes.heading} sortDirection={orderBy === 'assignment' ? order : false}>
                                                                <TableSortLabel
                                                                    direction={orderBy === 'assignment' ? order : 'asc'}
                                                                    active
                                                                    // ={orderBy === 'assignment'}
                                                                    IconComponent={ArrowDropDownIcon}
                                                                    onClick={() => sortProperties('assignment')}
                                                                >
                                                                    ASSIGNMENT
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            <TableCell className={classes.heading} sortDirection={orderBy === 'assigned_on' ? order : false}>
                                                                <TableSortLabel
                                                                    direction={orderBy === 'assigned_on' ? order : 'asc'}
                                                                    active
                                                                    // ={orderBy === 'assigned_on'}
                                                                    IconComponent={ArrowDropDownIcon}
                                                                    onClick={() => sortProperties('assigned_on')}
                                                                >
                                                                    ASSIGNED ON
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            <TableCell className={classes.heading} sortDirection={orderBy === 'assigned_by' ? order : false}>
                                                                <TableSortLabel
                                                                    direction={orderBy === 'assigned_by' ? order : 'asc'}
                                                                    active
                                                                    // ={orderBy === 'assigned_by'}
                                                                    IconComponent={ArrowDropDownIcon}
                                                                    onClick={() => sortProperties('assigned_by')}
                                                                >
                                                                    ASSIGNED BY
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            <TableCell className={classes.heading} sortDirection={orderBy === 'send_for_review_date' ? order : false}>
                                                                <TableSortLabel
                                                                    direction={orderBy === 'send_for_review_date' ? order : 'asc'}
                                                                    active
                                                                    // ={orderBy === 'send_for_review_date'}
                                                                    IconComponent={ArrowDropDownIcon}
                                                                    onClick={() => sortProperties('send_for_review_date')}
                                                                >
                                                                    COMPLETED ON
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            <TableCell className={classes.heading}>ACTIONS</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className={classes.tbody}>
                                                        {assignments.map((row, index) => (
                                                            <StyledTableRow
                                                                key={index}
                                                                className={classes.cell}
                                                            >
                                                                <TableCell
                                                                    onClick={() => handleClick(row)}
                                                                >
                                                                    <div className={classes.built}>
                                                                        <Avatar
                                                                            src={
                                                                                row.assigned_profile_url
                                                                                    ? row.assigned_profile_url
                                                                                    : ""
                                                                            }
                                                                            alt="profile"
                                                                            style={{ marginRight: "10px" }}
                                                                            className={classes.small}
                                                                        />
                                                                        <div className={classes.name}>{row.assigned_to}</div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell
                                                                    className={`${userId !== row.assigned_to_id &&
                                                                    row.status !== "PENDING REVIEW"
                                                                        ? classes.disabled
                                                                        : ""
                                                                    } ${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                                    }`}
                                                                >
                                                                    {row.tool_type}
                                                                </TableCell>

                                                                <Tooltip title={row?.assignment?.length === 0 ? 'None' : row.assignment}>
                                                                    <TableCell
                                                                        className={`${userId !== row.assigned_to_id &&
                                                                        row.status !== "PENDING REVIEW"
                                                                            ? ''
                                                                            : classes.overflow
                                                                        } ${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                                        }`}
                                                                    >
                                                                        {row?.assignment?.length > 50
                                                                            ? `${row.assignment.slice(0, 50)}  ...`
                                                                            : row.assignment}
                                                                    </TableCell>
                                                                </Tooltip>
                                                                <TableCell
                                                                    className={`${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                                    }`}
                                                                >
                                                                    {moment(new Date(row.assigned_on)).format(
                                                                        "MMM DD YYYY"
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    className={`${row.status === "NO PASS" ? classes.failedTableRow : ""}`}
                                                                >
                                                                    {row.assigned_by}
                                                                </TableCell>
                                                                <TableCell
                                                                    className={
                                                                        userId !== row.assigned_to_id &&
                                                                        row.status !== "PENDING REVIEW"
                                                                            ? classes.disabled
                                                                            : ""
                                                                    }
                                                                >
                                                                    {moment(new Date(row.send_for_review_date)).format(
                                                                        "MMM DD YYYY"
                                                                    )}
                                                                </TableCell>
                                                                {showAction(row, index)}
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            )}
                                        </TableContainer>
                                        <div>
                                            <div className={classes.paginationLabel}>
                                                Showing
                                                {' '}
                                                {(page - 1) * 10 + 1}
                                                {' '}
                                                to
                                                {' '}
                                                {(page - 1) * 10
                                                    + (assignments && assignments.length ? assignments.length : 0)}
                                                {' '}
                                                of
                                                {' '}
                                                {total ? total : 0}
                                                {' '}
                                                entries
                                            </div>
                                            <Pagination
                                                className={classes.pagination}
                                                color="primary"
                                                count={
                                                    total
                                                        ? Math.ceil(total / 10)
                                                        : 0
                                                }
                                                shape="rounded"
                                                page={page}
                                                onChange={handlePageChange}
                                            />
                                        </div>

                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            )}

        </div>
    );
}


Helpdesk.propTypes = {
    userData: PropTypes.object,
    fetchUserData: PropTypes.func,
    assignments: PropTypes.array,
    myAssignmentCount: PropTypes.number,
    allAssignmentCount: PropTypes.number,
    completedAssignmentCount: PropTypes.number,
    readyForReviewCount: PropTypes.number,
    fetchAssignments: PropTypes.func,
    fetchScoreList: PropTypes.func,
    scores: PropTypes.array,
    totalScore: PropTypes.number,
    pendingForReview: PropTypes.number,
    completedAssignCount: PropTypes.number,
    assignment_loading: PropTypes.bool,
    dashboard_listing: PropTypes.bool,
    permissions: PropTypes.array,
    userDetails: PropTypes.object,
    unopenedCompletedAssignments: PropTypes.number,
    unopenedPendingAssignments: PropTypes.number,
    reset: PropTypes.func,
    products: PropTypes.array,
    fetchAssignmentsCount: PropTypes.func,
    fetchHelpdeskTickets: PropTypes.func,
    countData: PropTypes.object,
};
const mapStateToProps = (state) => ({
    isLoading: state.sim.isLoading,
    totalSims: state.sim.total,
    assignments: state.sim.assignments ? state.sim.assignments.assignments : [],
    userData: state.sim.userData,
    allAssignmentCount: state.sim.assignments
        ? state.sim.assignments.allAssignmentCount
        : 0,
    myAssignmentCount: state.sim.assignments
        ? state.sim.assignments.myAssignmentCount
        : 0,
    completedAssignmentCount: state.sim.assignments
        ? state.sim.assignments.completedAssignmentCount
        : 0,
    readyForReviewCount: state.sim.assignments
        ? state.sim.assignments.readyForReviewCount
        : 0,
    pendingForReview: state.sim.assignments
        ? state.sim.assignments.pendingForReview
        : 0,
    completedAssignCount: state.sim.assignments
        ? state.sim.assignments.completedAssignCount
        : 0,
    unopenedCompletedAssignments: state.sim.assignments
        ? state.sim.assignments.unopenedCompletedAssignments
        : 0,
    unopenedPendingAssignments: state.sim.assignments
        ? state.sim.assignments.unopenedPendingAssignments
        : 0,
    scores: state.sim.scores ? state.sim.scores.scores : [],
    totalScore: state.sim.scores ? state.sim.scores.total : 0,
    assignment_loading: state.sim.assignment_loading,
    dashboard_listing: state.sim.dashboard_listing,
    permissions: state.login.permissions,
    userDetails: state.login.user,
    products: state.sim.products ? state.sim.products : [],
    countData: state.sim.assignmentDataCount,
    helpdeskTickets: state.helpdesk.helpdeskTickets ?? [],
    helpdeskStats: state.helpdesk.helpdeskStats ?? {},
});

const mapDispatchToProps = {
    fetchAssignments,
    fetchHelpdeskTickets,
    fetchUserData,
    fetchTotalAssignments,
    fetchScoreList,
    reset,
    fetchAssignmentsCount,
};
export default connect(mapStateToProps, mapDispatchToProps)(Helpdesk);
