import React from 'react';
import useAlert from './UseAlert';
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from '@material-ui/core/styles';
import AlertPopupStyle from "./AlertPopup";

const AlertPopup = () => {
    const useStyles = makeStyles((theme) => AlertPopupStyle(theme));
    const classes = useStyles();
    const { text, type } = useAlert();

    if (text && type) {
        return (
            <Alert
                className={classes.alert}
                severity={type}
            >
                {text}
            </Alert>
        );
    } else {
        return <></>;
    }
};

export default AlertPopup;