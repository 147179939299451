import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Button,
    Paper,
    Popper,
    Divider,
    Tooltip,
    Modal,
} from "@material-ui/core";
import { connect } from "react-redux";
import { addAssignmentStatus, fetchUserResponse } from "../../redux/sims/actions";
import StyledLink from "../common/StyledLink";
import {
    checkUserResultExist,
    checkContinueLevel,
} from "../../services/result.service";
import { useLocation, useHistory } from "react-router-dom";
import { useStyles } from "./Levels";
import Checkbox from '@material-ui/core/Checkbox';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { userFinalScoreResponse } from "../../redux/sims/actions";
//import moment from 'moment';
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import VideocamIcon from "@material-ui/icons/Videocam";
import debug from "../../utils/debug";

const Levels = ({
    level,
    index,
    simId,
    fetchUserResponse,
    assignmentData,
    type,
    addAssignmentStatus,
    currentAssignmentStatus,
    document,
    hotLinks,
    captivate_learning_url,
    captivate_learning_video_url,
    elapsedTime,
    caseValuesArray,
    userResponse,
    userFinalScoreResponse,
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowRef, setArrowRef] = useState(null);
    const [userResultExist, setUserResultExist] = useState(false);
    const [opneModal, setOpenModal] = useState(false);
    const [simType, setSimType] = useState(type);
    const [mode, setMode] = useState("practice");
    const [activeClip, setActiveClip] = useState(0);
    const history = useHistory();
    const [exited, setExited] = useState(0);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const open = Boolean(anchorEl);
    const id = open ? "popper with arrow" : undefined;
    const levelTitles = [
        'Watch and learn.Gets tips and training as an expert agent navigate the call you will only watch and learn in this interaction.',
        '',
        "Same call-now it's your turn! You will speak and read using a full script displayed on the screen",
        "Same call-now it's your turn! You will read and speak using keywords displayed on the screen as a guide",
        '',
        "Same call-now it's your turn! The call automatically plays through from start to finish.This means no stopping and no re-recording your audio.It's as close as it gets to real call experience !"
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleArrowRef = (node) => {
        setArrowRef(node);
    };

    // Close Modal
    const handleClose = () => setOpenModal(false);

    useEffect(() => {
        if(Boolean(urlParams.get("exit")) && elapsedTime && exited ===0) {
      let lapsedTime = elapsedTime,
                totalSeconds = 0;
            if (userResponse.resumeLevel && userResponse.elapsedTime) {
        let floatVal;
        if (!isNaN(lapsedTime) && !isNaN(userResponse.elapsedTime)) {
          floatVal = lapsedTime + userResponse.elapsedTime;
        } else {
            floatVal = (parseFloat(((''+elapsedTime)
                  .replace(/\s/g, ''))
                  .replace('m', ".")
            ) + parseFloat(((''+userResponse.elapsedTime).replace(/\s/g, ''))
                  .replace('m', "."))
          );
        }
                totalSeconds = parseInt(floatVal) * 60 + parseInt((floatVal % 1).toFixed(2).substring(2))
                // const formattedTime = moment.utc(totalSeconds * 1000).format("HH:mm:ss");
                // lapsedTime = `${moment(formattedTime,"HH:mm:ss").minutes()}m ${moment(formattedTime,"HH:mm:ss").seconds()}s`
                lapsedTime = totalSeconds;
            }
            const payload = {
                ...caseValuesArray,
                isPost: true,
                assignment_id: userResponse.assignment_id,
                elapsedTime: lapsedTime,
                totalSeconds: totalSeconds,
                exit_or_restart: true,
            };
            setExited(exited + 1);
            userFinalScoreResponse(userResponse._id, payload);
        }
    },[elapsedTime, urlParams, caseValuesArray, userFinalScoreResponse, userResponse, exited])

    useEffect(() => {
        const asyncFunctionCall = async () => {
            const payload = {
                simId: assignmentData?.sim_id ? assignmentData.sim_id : simId,
                simLevelId: level.level_id,
                ...(assignmentData?.assignment_id
                    ? { assignment_id: assignmentData.assignment_id }
                    : {}),
            };
            const userId =
                assignmentData?.assigned_to_id || assignmentData?.agent_user_id;
            const exist = await checkUserResultExist(payload, userId);
            setUserResultExist(exist);
        };

        asyncFunctionCall();
        assignmentData && assignmentData.sim_type
            ? setSimType(assignmentData.sim_type)
            : setSimType(type);
    }, [assignmentData, level, simId, type]);

    const startPractice = () => {
        setMode("practice");
        checkContinueLevel(simId, level.level_id, "practice")
            .then((res) => {
            if (res.data.continue_agent) {
                setOpenModal(true);
                setActiveClip(res.data.active_clip_index);
            } else {
                    if (
                        assignmentData &&
                        assignmentData.assignment_id &&
                        assignmentData.status &&
                        (assignmentData.status.toLowerCase() !== 'completed')
                    ) {
                    const data = {
                        _id: assignmentData.assignment_id,
                        status: "IN PROGRESS",
                    };
                    addAssignmentStatus(data);
                }
                startOver("practice");
            }
        });
    };

    const startAutoPlay = () => {
        setMode("autoplay");
        checkContinueLevel(simId, level.level_id, "autoplay")
            .then((res) => {
            if (res.data.continue_agent) {
                setOpenModal(true);
                setActiveClip(res.data.active_clip_index);
            } else {
                    if (
                        assignmentData &&
                        assignmentData.assignment_id &&
                        assignmentData.status &&
                        (assignmentData.status.toLowerCase() !== 'completed')
                    ) {
                    const data = {
                        _id: assignmentData.assignment_id,
                        status: "IN PROGRESS",
                    };
                    addAssignmentStatus(data);
                }
                startOver("autoplay");
            }
        });
    };

    const startOver = (simMode) => {
        fetchUserResponse({
            sim_level_id: level.level_id,
            sim_id: simId,
            mode: simMode ? simMode : mode,
            resumeLevel: false,
            assignment_id: assignmentData ? assignmentData.assignment_id : undefined,
        });

        if (assignmentData && assignmentData.assignment_id && assignmentData.status && assignmentData.status.toLowerCase() !== 'completed') {
            const data = {
                _id: assignmentData.assignment_id,
                status: "IN PROGRESS",
            };
            addAssignmentStatus(data);
        }

        history.push({
            pathname: "/sims/takesim",
            search: assignmentData?.assignment_id
                ? `?levelId=${level.level_id}&assignment_id=${
                    assignmentData.assignment_id
                }${
                    assignmentData?.reviewer ? "&reviewer=true" : "&reviewer=false"
                }&continueSimLevel=false&order=${level.level_order}`
                : `?levelId=${level.level_id}${
                    assignmentData?.reviewer ? "&reviewer=true" : "&reviewer=false"
                }&continueSimLevel=false&order=${level.level_order}`,
            state: {
                viewResult: false,
                assignment: assignmentData ? assignmentData : {},
                type: simType,
                documents: document,
                hotLinks: hotLinks,
                screenRecording: level.screenRecording,
                captivate_learning_video_url: captivate_learning_video_url,
                captivate_learning_url: captivate_learning_url,
            },
        });
    };

    // If agent continue for level
    const resumeLevel = () => {
        fetchUserResponse({
            sim_level_id: level.level_id,
            sim_id: simId,
            mode: mode,
            resumeLevel: true,
            assignment_id: assignmentData ? assignmentData.assignment_id : undefined,
        });

        if (assignmentData && assignmentData.assignment_id && assignmentData.status && assignmentData.status.toLowerCase() !== 'completed') {
            const data = {
                _id: assignmentData.assignment_id,
                status: "IN PROGRESS",
            };
            addAssignmentStatus(data);
        }

        history.push({
            pathname: "/sims/takesim",
            search: assignmentData?.assignment_id
                ? `?levelId=${level.level_id}&assignment_id=${
                    assignmentData.assignment_id
                }${
                    assignmentData?.reviewer ? "&reviewer=true" : "&reviewer=false"
                }&continueSimLevel=true&activeIndex=${activeClip}&order=${level.level_order}`
                : `?levelId=${level.level_id}${
                    assignmentData?.reviewer ? "&reviewer=true" : "&reviewer=false"
                }&continueSimLevel=true&activeIndex=${activeClip}&order=${level.level_order}`,
            state: {
                viewResult: false,
                assignment: assignmentData ? assignmentData : {},
                type: simType,
                documents: document,
                hotLinks: hotLinks,
                screenRecording: level.screenRecording,
                captivate_learning_video_url: captivate_learning_video_url,
                captivate_learning_url: captivate_learning_url,
            },
        });
    };

    debug(">>>> LEVEL: %o", level);

    return (
        <Box
            display="flex"
            justifyContent="space-around"
            color="default"
            mb={1}
            borderRadius={16}
            className={classes.root}
            onMouseLeave={handlePopoverClose}
        >
            <Modal
                open={opneModal}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    {(level.screenRecording && level.screen_recordings?.length) ? (
                        <>
                            <p>You can not resume levels with partial Screen Sharing recordings.</p>
                            <button
                                className={classes.startBtn}
                                style={{ marginRight: "1rem" }}
                                onClick={() => startOver()}
                            >
                                START OVER
                            </button>
                        </>
                    ) : (
                        <>
                    <p>You already started this level. Do you want to resume?</p>
                    <button
                        className={classes.startBtn}
                        style={{ marginRight: "1rem" }}
                        onClick={() => startOver()}
                    >
                        START OVER
                    </button>
                    <button className={classes.startBtn} onClick={resumeLevel}>
                        RESUME
                    </button>
                        </>
                   )}
                </div>
            </Modal>
            <Box display="flex" alignItems="center" className={classes.flexItem1}>
                {
                    level.completed_attempts > 0 &&
                    <div className={classes.flex2item1}>
                        <Checkbox
                            className= {classes.checkBox}
                            icon={<CircleUnchecked/>}
                            checkedIcon={<CircleCheckedFilled className={classes.chckBoxFilled}/>}
                            checked={level.completed_attempts > 0}
                        />
                    </div>
                }
                {level.screenRecording ? (
                    <Tooltip title={'A level in this SIM allows screen share recordings'}
                             placement="top"
                    >
                        <div style={{
                            marginRight: '1em',
                            verticalAlign: 'middle',
                            display: 'inline-block',
                        }}>
                            <ScreenShareIcon fontSize="small"/>
                        </div>
                    </Tooltip>
                ) : (
                    <></>
                )}
                <Tooltip
                    title={levelTitles[level?.level_order] ?? ''}
                    placement="top"
                >
                    <div className={level.completed_attempts > 0 ? '' : classes.levelDiv}>
                        <div className={classes.textBold}>
                            LEVEL&nbsp;
                            {index + 1}
                        </div>
                        <div>{level.level_name}</div>
                    </div>
                </Tooltip>
            </Box>
            <div className={classes.flexItem3}>
                {simType === "hiring" ? (
                    <Tooltip
                        title={currentAssignmentStatus?.status === "PENDING REVIEW" ? "Locked" : ""}
                        placement="top"
                    >
            <span>
              <Button
                  variant="contained"
                  className={classes.startBtn}
                  disableElevation
                  color="primary"
                  size="small"
                  aria-describedby={id}
                  onClick={startAutoPlay}
                  disabled={currentAssignmentStatus?.status === "PENDING REVIEW"}
              >
                START
              </Button>
            </span>
                    </Tooltip>
                ) : (
                    <Tooltip
                        title={currentAssignmentStatus?.status === "PENDING REVIEW" ? "Locked" : ""}
                        placement="top"
                    >
            <span>
              <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  className={classes.startBtn}
                  size="small"
                  onClick={handleClick}
                  aria-describedby={id}
                  disabled={currentAssignmentStatus?.status === "PENDING REVIEW"}
              >
                START
              </Button>
            </span>
                    </Tooltip>
                )}
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.flexItem2}>
                <div className={classes.status}>
                    {((level.total_attempts === 0) && !userResultExist) && <div>Not yet completed ...</div>}
                    {level.total_attempts !== 0 && (
                        <div>
                            Completed {level.completed_attempts} times in{" "}
                            {level.total_attempts} attempts
                            <br />
                            {level?.screen_recordings?.length ? (
                                <Tooltip
                                    title={`User has ${level.screen_recordings.length} screen recordings for this level`}
                                    placement="top"
                                >
                                    <div style={{
                                        marginRight: '1em',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                    }}>
                                        <VideocamIcon fontSize="small" />
                                    </div>
                                </Tooltip>
                            ) : (<></>)}
                            {userResultExist && level?.completed_attempts > 0 ? (
                                <StyledLink
                                    to={{
                                        pathname: "/sims/takesim",
                                        search: assignmentData?.assignment_id
                                            ? `?levelId=${level.level_id}&assignment_id=${
                                                assignmentData.assignment_id
                                            }&sim_id=${assignmentData.sim_id}&user_id=${
                                                assignmentData.assigned_to_id ||
                                                assignmentData.agent_user_id
                                            }${
                                                assignmentData?.reviewer
                                                    ? "&reviewer=true"
                                                    : "&reviewer=false"
                                            }&order=${level.level_order}`
                                            : `?levelId=${level.level_id}${
                                                assignmentData?.reviewer
                                                    ? "&reviewer=true"
                                                    : "&reviewer=false"
                                            }&order=${level.level_order}`,
                                        type: simType,
                                        state: {
                                            viewResult: true,
                                            assignment: assignmentData,
                                        },
                                    }}
                                >
                                    <u>View Results</u>
                                </StyledLink>
                            ) : (userResultExist ? (<p>No results found</p>) : (<p>Checking for results...</p>)
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="left"
                className={classes.popper}
                disablePortal={false}
                onMouseLeave={handlePopoverClose}
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: "scrollParent",
                    },
                    arrow: {
                        enabled: true,
                        element: arrowRef,
                    },
                }}
            >
                {/* arrow element */}
                <div
                    data-popper-arrow
                    className={classes.rightTriangle}
                    ref={handleArrowRef}
                />
                <Paper elevation={2} className={classes.popperPaper}>
                    {
                        level.level_order !== 4 ?
                            <div>
                                <Tooltip
                                    title="Start here and you'll be able to control the pace of the call with pause, forward and back buttons.
              You'll also have the option to listen to and re-record your audio !"
                                    placement="top"
                                >
                <span>
                  <Button
                      variant="contained"
                      className={classes.practiceBtn}
                      disableElevation
                      onClick={startPractice}
                  >
                    Start in Practice mode
                  </Button>
                </span>
                                </Tooltip>
                                <br />
                            </div>
                            : null
                    }
                    <Tooltip
                        title="Start here and the call automatically plays through from start to finish.This means no stopping and
           no re-recording your audio.It's as close as it gets to real call experience !"
                        placement="bottom"
                    >
          <span>
            <Button
                variant="contained"
                className={classes.autoplayBtn}
                disableElevation
                onClick={startAutoPlay}
            >
              Start in Autoplay mode
            </Button>
          </span>
                    </Tooltip>
                </Paper>
            </Popper>
        </Box>
    );
};

Levels.propTypes = {
    level: PropTypes.shape({
        level_order: PropTypes.number,
        level_name: PropTypes.string,
        total_attempts: PropTypes.number,
        completed_attempts: PropTypes.number,
        level_id: PropTypes.string,
        screenRecording: PropTypes.bool,
    }),
    index: PropTypes.number,
    simId: PropTypes.string,
    type: PropTypes.string,
    fetchUserResponse: PropTypes.func,
    assignmentData: PropTypes.object,
    addAssignmentStatus: PropTypes.func,
};
const mapStateToProps = (state, props) => ({
    elapsedTime: state.sim.elapsedTime,
    caseValuesArray: state.sim.caseValuesArray,
    userResponse: state.sim.userResponse,
});
const mapDispatchToProps = {
    fetchUserResponse,
    addAssignmentStatus,
    userFinalScoreResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(Levels);
