import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Dashboard from '../pages/dashboard/Dashboard.jsx';
import Sims from '../pages/sim/Sims.jsx';
import CreateSims from '../pages/sim/CreateSims';
import Admin from '../pages/admin/Admin.jsx';
import Tools from '../pages/tools/Tools';
import Account from '../pages/account/Account';
import Settings from '../pages/Settings.jsx';
import TakeSim from '../components/sim/takeSim/TakeSim.jsx';
import PermissionDenied from '../pages/PermissionDenied';
import CreateUser from '../components/admin/CreateUser.jsx';
import Login from '../pages/login/Login.jsx';
import ProtectedRouter from './ProtectRoute';
import { checkAuthorization } from '../redux/authentication/action';
import ForgotPassword from '../pages/forgotpassword/ForgotPassword.jsx';
import Helpdesk from "../pages/helpdesk/Helpdesk";

const Common = ({ checkAuthorization }) => {
    useEffect(() => {
        checkAuthorization();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Switch>
            <Route
                path="/login"
                exact
                render={() => (
                    <Login />
                )}
            />
            <Route
                path="/login?allowNewCompany=(:allowNewCompany)"
                exact
                render={() => (
                    <Login />
                )}
            />
            <ProtectedRouter path="/" exact component={Home} />
            <ProtectedRouter
                path="/dashboard"
                title="DASHBOARD"
                exact
                component={Dashboard}
            />
            <ProtectedRouter
                path="/sims"
                title="SIMS"
                exact
                component={Sims}
            />
            <ProtectedRouter
                path="/sims/createsim"
                title="SIMS"
                subtitle="CREATE SIM"
                exact
                component={CreateSims}
            />
            <ProtectedRouter
                path="/sims/takesim"
                title="SIMS"
                subtitle="TAKE SIM"
                exact
                component={TakeSim}
            />
            <ProtectedRouter
                path="/admin"
                title="ADMIN"
                exact
                component={Admin}
            />
            <ProtectedRouter
                path="/tools"
                title="TOOLS"
                exact
                component={Tools}
            />
            <ProtectedRouter
                path="/account"
                title="ACCOUNT"
                exact
                component={Account}
            />
            <ProtectedRouter
                path="/settings"
                title="SETTINGS"
                exact
                component={Settings}
            />
            <ProtectedRouter
                path="/helpdesk"
                title="HELPDESK"
                exact
                component={Helpdesk}
            />
            <ProtectedRouter
                path="/admin/createuser"
                title="ADMIN"
                exact
                component={CreateUser}
            />
            <Route
                path="/permissiondenied"
                title="Permission Denied"
                exact
                component={PermissionDenied}
            />
            <Route
                path="/forgotpassword"
                title="Forgot Password"
                exact
                component={ForgotPassword}
            />
            <Route path="*" component={NotFound} />
        </Switch>
    );
};

Common.propTypes = {
    checkAuthorization: PropTypes.func,
};

const mapDispatchToProps = {
    checkAuthorization,
};

export default connect(null, mapDispatchToProps)(Common);
