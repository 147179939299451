import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import './assets/fonts/fonts.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import {AlertProvider} from "./components/common/AlertContext";

window.IS_DEVELOPMENT = (process.env.REACT_APP_STAGE !== 'prod');

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <AlertProvider>
                <App />
            </AlertProvider>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
);
