const helpdesk = {
    tickets: {
        view: {
            task: 'Helpdesk Ticket',
            type: 'view',
        },
        create: {
            task: 'Helpdesk Ticket',
            type: 'create',
        },
        update: {
            task: 'Helpdesk Ticket',
            type: 'update',
        },
        delete: {
            task: 'Helpdesk Ticket',
            type: 'delete',
        },
    },
    ticket_entries: {
        view: {
            task: 'Helpdesk Ticket Entries',
            type: 'view',
        },
        create: {
            task: 'Helpdesk Ticket Entries',
            type: 'create',
        },
        update: {
            task: 'Helpdesk Ticket Entries',
            type: 'update',
        },
        delete: {
            task: 'Helpdesk Ticket Entries',
            type: 'delete',
        },
    },
    ticket_attachments: {
        view: {
            task: 'Helpdesk Ticket Attachments',
            type: 'view',
        },
        create: {
            task: 'Helpdesk Ticket Attachments',
            type: 'create',
        },
        update: {
            task: 'Helpdesk Ticket Attachments',
            type: 'update',
        },
        delete: {
            task: 'Helpdesk Ticket Attachments',
            type: 'delete',
        },
    },
};
export default helpdesk;
