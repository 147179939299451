/**
 * @date Dec 9 2024
 * @author Gonzalo Nuñez <griboni@snowfly.com>
 *
 * Classes that handle recording media streams (either coming from audio sources like a microphone, video sources like
 *  a webcam, or a screen shared by the user)
 */

import debug from "./debug";

const AUDIO_CODEC = 'audio/mp4';//'audio/webm;codecs=opus';
const VIDEO_CODEC = 'video/mp4';//'video/webm;codecs=opus,vp8';

/**
 * Base class that isolates creating a media stream and provides methods to
 * "record" data coming from the stream via a "recorder". The recorder is
 * created by derived child classes.
 */
export class StreamRecorder {
    streamType = 'unknown';
    streamExtension = 'stream';
    #stream = null;
    streamOptions = {};
    dataTimer = 1000;
    recorder = null;
    prepared = false;
    fileName = '';
    fileHandle = null;
    fileWriteHandle = null;
    filePos = 0;
    fileClosed = false;
    closed = false;
    locked = false;

    constructor () {
        this.streamType = 'unknown';
        this.streamExtension = 'stream';
        this.#stream = null;
        this.streamOptions = {};
        this.dataTimer = 1000;
        this.recorder = null;
        this.prepared = false;
        this.fileName = '';
        this.fileHandle = null;
        this.fileWriteHandle = null;
        this.filePos = 0;
        this.fileClosed = false;
    }

    async close() {
        this.locked = true;
        if (this.fileWriteHandle && !this.closed) {
            if (this.fileWriteHandle.getWriter().desiredSize < 1) {
                await this.fileWriteHandle.close();
                this.fileWriteHandle = null;
                this.closed = true;
                this.locked = false;
            } else {
                setTimeout(this.close, 100);
            }
        }
    }

    /**
     * Initializes files in the private file system where the stream's contents
     * will be stored. Derived child classes are responsible for initializing the
     * stream and the recorder by overriding this method.
     *
     * @returns {Promise}
     */
    async prepare() {
        let self = this;

        return new Promise((resolve/*, reject*/) => {
            if (self.prepared) {
                resolve(true);
                return true;
            }

            //debug('**** Recorder is preparing: %o', self);
            self.fileName = `${self.#stream.id}.${self.streamExtension}`;
            navigator.storage.getDirectory().then(async (fsRoot) => {
                fsRoot.getFileHandle(self.fileName, {create: true}).then((handle) => {
                    return new Promise((innerResolve/*, innerReject*/) => {
                        self.fileHandle = handle;
                        if (handle.createSyncAccessHandle) {
                            //debug('using createSyncAccessHandle to create file in private file system');
                            handle.createSyncAccessHandle().then((handle2) => {
                                self.fileWriteHandle = handle2;
                                self.fileWriteHandle.truncate(0).then(() => {
                                    innerResolve();
                                });
                            });
                        } else {
                            //debug('using createWritable to create file in private file system');
                            handle.createWritable({keepExistingData: false}).then((handle2) => {
                                self.fileWriteHandle = handle2;
                                self.fileWriteHandle.truncate(0).then(() => {
                                    innerResolve();
                                });
                            });
                        }
                    });
                }).then(() => {
                    if (self.recorder && self.#stream) {
                        if (!self.prepared) {
                            self._initHandlers();
                        }

                        self.prepared = true;
                        //debug('++++ Recorder is ready to go: %o', self);
                        resolve(self.prepared);
                    }
                });
            });
        });
    }

    /**
     * Returns the stream used by this recorder.
     *
     * @returns {MediaStream}
     */
    get stream() {
        return this.#stream;
    }

    /**
     * Sets the stream used by this recorder.
     *
     * @param newStream
     * @returns {void}
     */
    set stream(newStream) {
        this.#stream = newStream;
    }

    /**
     * Indicates if the stream is ready
     *
     * @returns {null}
     */
    get ready() {
        return this.#stream && this.#stream.active;
    }

    /**
     * Starts recording
     */
    async start() {
        if (!this.prepared) {
            await this.prepare();
            await this._initHandlers();
        }
        //debug('<=================> ScreenRecorder ready: %o', this);

        await this.recorder.start(this.dataTimer);

        //debug('Recorder started: %o', this);
        const ev = new CustomEvent('recorder.started', {detail: {streamRecorder: this}});
        document.dispatchEvent(ev);
    }

    /**
     * Stops the recording
     */
    async stop() {
        if (this.prepared) {
            await this.recorder?.stop();
            await this._cleanHandlers();
        }

        //debug('Recorder stopped: %o', this);
        const ev = new CustomEvent('recorder.stopped', {detail: {streamRecorder: this}});
        document.dispatchEvent(ev);
    }

    /**
     * Pauses the recording. This is different from stopping the recording, as
     * stop gets rid of the event handlers and closes the stream and
     * files used to record the stream contents.
     */
    async pause() {
        await this.recorder.pause();

        const ev = new CustomEvent('recorder.paused', {detail: {streamRecorder: this}});
        document.dispatchEvent(ev);
    }

    /**
     * Resumes the recording.
     */
    async resume() {
        await this.recorder.resume();

        const ev = new CustomEvent('recorder.resumed', {detail: {streamRecorder: this}});
        document.dispatchEvent(ev);
    }

    /**
     * Initializes the event handlers used to fetch the stream's contents and
     * store them, as well as to clean up after the stream is ended.
     */
    async _initHandlers() {
        if (this.prepared) {
            await this._cleanHandlers();
        }

        this.recorder.addEventListener('dataavailable', this.onRecorderDataAvailable.bind(this));
        this.recorder.addEventListener('start', this.onRecorderStarted.bind(this));
        this.recorder.addEventListener('stop', this.onRecorderStopped.bind(this));
        this.recorder.addEventListener('pause', this.onRecorderPaused.bind(this));
        this.recorder.addEventListener('resume', this.onRecorderResumed.bind(this));
        this.recorder.addEventListener('error', this.onRecorderError.bind(this));
        //debug('>>>> Added Recorder Event Listeners');
    }

    /**
     * Removes the event handlers.
     */
    async _cleanHandlers() {
        if (this.recorder) {
            this.recorder.removeEventListener('dataavailable', this.onRecorderDataAvailable.bind(this));
            this.recorder.removeEventListener('start', this.onRecorderStarted.bind(this));
            this.recorder.removeEventListener('stop', this.onRecorderStopped.bind(this));
            this.recorder.removeEventListener('pause', this.onRecorderPaused.bind(this));
            this.recorder.removeEventListener('resume', this.onRecorderResumed.bind(this));
            this.recorder.removeEventListener('error', this.onRecorderError.bind(this));
        }
        //debug('<<<< Removed Recorder Event Listeners');
    }

    async onRecorderStarted(evt) {
        //console.debug('Recorder Started Event: %o', evt);
    }

    async onRecorderStopped(evt) {
        //console.debug('Recorder Stopped Event: %o', evt);
        this.closed = true;
    }

    async onRecorderPaused(evt) {
        //console.debug('Recorder Paused Event: %o', evt);
    }

    async onRecorderResumed(evt) {
        //console.debug('Recorder Resumed Event: %o', evt);
    }

    /**
     * Event handler for the error event triggered by the recorder in case any
     * error occurs.
     *
     * Adds the current recorder object to the detail event member and then
     * redispatches the event.
     *
     * @param {Event} evt
     */
    async onRecorderError(evt) {
        //console.debug('Recorder Error Event: %o', evt);

        //console.error('Error recording stream: %o', evt.error);
        evt.detail.recorder = this;
        document.dispatchEvent(evt);
    }

    /**
     * Event handler for the dataavailable event that is triggered by the
     * recorder.
     *
     * Stores the stream data chunk included in the event, and notifies any
     * listeners about the new data coming from the stream.
     *
     * @param {Event} evt
     */
    async onRecorderDataAvailable(evt) {
        //console.debug('Recorder Data Available Event: %o', evt);
        try {
            if (this.recorder && this.#stream && !this.closed && this.fileWriteHandle && !this.locked) {
                const blob = evt.data,
                    bufSize = blob.size,
                    buffer = new Uint8Array(await blob.arrayBuffer());
                try {
                    this.fileWriteHandle.write(buffer, {at: this.filePos});
                } catch (err) {
                    debug('Could not write to file: %o', err);
                    return;
                }
                this.filePos += bufSize;
                //debug('Wrote %d bytes to file handler %s: %o', bufSize, this.fileName, this.fileHandle);
                await this.notify(this.streamType, evt.data);
            } else {
                debug('Received data-available event without a valid recorder or stream');
                debug('Recorder: %o', this.recorder);
                debug('Stream: %o', this.#stream);
            }

        } catch (err) {
            console.error(err);
        }
    }

    /**
     * Event handler for the ended event triggered by the recorder.
     *
     * Closes the stream and files.
     *
     * @param {*} evt
     */
    async onShareEnded(evt) {
        debug('Recorder Ended Event: %o', evt);
        console.info('Share ended for recorder : %s', this.recorder);
        await this.stop();
        if (this.stream) {
            let self = this;
            this.stream.getTracks().forEach(track => {
                self.stream.removeTrack(track);
            });
        }

        try {
            this.fileWriteHandle.close();
            debug('FileWriteHandle closed in onShareEnded event for file %s', this.fileName);
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * Notifies listeners that new data arrived from the stream.
     *
     * @param {*} type
     * @param {*} blob
     */
    async notify(type, blob) {
        if (!this.#stream) {
            return;
        }

        const bufSize = blob.size,
            buffer = new Uint8Array(await blob.arrayBuffer());
        const ev = new CustomEvent(
            'recorder.data-available',
            {
                detail: {
                    uuid: this.#stream.id,
                    type: type,
                    data: buffer,
                    size: bufSize,
                    dataType: blob.type
                }
            }
        );
        document.dispatchEvent(ev);
    }
}

/**
 * Class that specializes in recording data from an Audio Stream.
 */
export class AudioRecorder extends StreamRecorder {
    audioDevId = null;

    constructor(audioDeviceId = null) {
        super();

        this.streamType = 'audio';
        this.streamExtension = 'ogg';
        this.audioDevId = audioDeviceId;
        this.streamOptions = {
            audio: true,
            sampleRate: 44100,
        };

    }

    /**
     * @see {StreamRecorder.prepare}
     *
     * @return {Promise}
     */
    async prepare() {
        let self = this;

        return new Promise((resolve/*, reject*/) => {
            if (self.prepared) {
                resolve(true);
                return true;
            }

            if (self.audioDevId) {
                self.streamOptions.deviceId = { exact: self.audioDevId }
            }
            navigator.mediaDevices.getUserMedia(self.streamOptions).then((stream) => {
                let contextOptions = {
                        latencyHint: "interactive",
                    },
                    recorderOptions = {
                        mimeType: AUDIO_CODEC,
                    };

                // Firefox does not like having the sampleRate entry
                if (navigator.userAgent.indexOf('Firefox') < 0) {
                    contextOptions.sampleRate = 44100;
                    recorderOptions.sampleRate = 44100;
                }
                self.stream = stream;

                const ctx = new AudioContext(contextOptions);
                const src = ctx.createMediaStreamSource(self.stream);
                const audioDst = ctx.createMediaStreamDestination();
                const gain = new GainNode(ctx);
                gain.gain.value = 2;
                gain.gain.volume = 2;
                gain.gain.minVolume = 1;
                src.connect(gain);
                gain.connect(audioDst);

                self.recorder = new MediaRecorder(audioDst.stream, recorderOptions);
            }).then(() => {
                super.prepare().then(() => {
                    //debug('<=================> AudioRecorder ready: %o', self);
                    resolve(true);
                });
            });
        });
    }

    async stop() {
        await super.stop();

        if (this.stream) {
            let tracks = this.stream.getAudioTracks(),
                self = this;
            tracks.forEach((track) => {
                track.stop();
                self.stream.removeTrack(track);
            });
        }
    }

}

/**
 * Class that specializes in recording data produced by a Video Stream that
 * comes from sharing a screen, window or browser tab.
 */
export class ScreenRecorder extends StreamRecorder {
    videoDevId = null;

    constructor(videoDeviceId = null) {
        super();

        this.streamType = 'video';
        this.streamExtension = 'webm';
        this.videoDevId = videoDeviceId;
        this.streamOptions = {
            video: {
                displaySurface: ["monitor"],
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                frameRate: { ideal: 30 },
            },
            audio: true
        };
        if (videoDeviceId) {
            this.streamOptions.video.deviceId = { exact: videoDeviceId };
        }
    }

    /**
     * @see {StreamRecorder.prepare}
     *
     * @return {Promise}
     */
    async prepare() {
        let self = this;

        return new Promise((resolve, reject) => {
            if (self.prepared) {
                resolve(true);
                return true;
            }

            if (!self.stream && !self.recorder) {
                navigator.mediaDevices.getDisplayMedia(this.streamOptions).then((stream) => {
                    self.stream = stream;
                    //debug('Video Stream = %o', self.stream);

                    self.recorder = new MediaRecorder(
                        self.stream,
                        {
                            mimeType: VIDEO_CODEC,//;codecs=opus,vp8'
                        }
                    );
                }).then(() => {
                    super.prepare().then(() => {
                        resolve(true);
                    });
                }).catch((err) => {
                    console.error(err);
                    reject(err);
                });
            }

        });
    }

    /**
     * @see {ScreenRecorder._initHandlers}
     */
    async _initHandlers() {
        await super._initHandlers();

        if (this.stream) {
            let tracks = this.stream.getVideoTracks();
            let self = this;
            tracks.forEach((track) => {
                track.addEventListener('ended', self.onShareEnded.bind(self));
            });
        }

        //debug('>>>> Added ScreenRecorder Event Listeners');
    }

    /**
     * @see {ScreenRecorder._cleanHandlers}
     */
    async _cleanHandlers() {
        await super._cleanHandlers();
        /*
        if ((navigator.userAgent.indexOf('Firefox') > -1) && this.getDataTimer) {
            clearInterval(this.getDataTimer);
            this.getDataTimer = null;
        }*/

        if (this.stream) {
            let tracks = this.stream.getVideoTracks();
            let self = this;
            tracks.forEach((track) => {
                track.removeEventListener('ended', self.onShareEnded.bind(self));
            });
        }
        //debug('<<<< Removed ScreenRecorder Event Listeners');
    }

    async stop() {
        await super.stop();

        if (this.stream) {
            let tracks = this.stream.getVideoTracks(),
                self = this;
            tracks.forEach((track) => {
                track.stop();
                self.stream.removeTrack(track);
            });
        }
    }
}
