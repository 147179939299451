import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import Conversation from "./Conversation";
import Table from "@material-ui/core/Table";
import React, {useEffect} from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setEditSimStep} from "../../redux/sims/actions";
import {fetchHelpdeskTickets} from "../../redux/helpdesk/actions";
import {Icon,  Tooltip} from "@material-ui/core";
import {addHelpdeskTicketEntry, closeHelpdeskTicket, deleteHelpdeskTicket} from "../../services/result.service";
import {useHistory} from "react-router-dom";
import {dateFormat} from "../../utils/i18n";
import HelpdeskPermissions from '../../permissions/helpdesk';
import { permissionCheck } from '../../utils/permissionCheck';
import Typography from "@material-ui/core/Typography";
import TicketDetails from "./TicketDetails";
import useAlert from "../common/UseAlert";

const HelpRequestTable = ({
    tickets,
    classes,
    setEditSimStep,
    onSort,
    orderBy,
    order,
    onTicketsChange,
}) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogTicket, setDialogTicket] = React.useState(null);
    const [canViewTickets, setCanViewTickets] = React.useState(false);
    const [canCloseTickets, setCanCloseTickets] = React.useState(false);
    const [canUpdateTickets, setCanUpdateTickets] = React.useState(false);
    const [canDeleteTickets, setCanDeleteTickets] = React.useState(false);

    const history = useHistory();
    const {setAlert} = useAlert();
    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(
        () => {
            setCanViewTickets(
                permissionCheck(HelpdeskPermissions.tickets.view.task, HelpdeskPermissions.tickets.view.type)
            );
            setCanCloseTickets(
                permissionCheck(HelpdeskPermissions.tickets.update.task, HelpdeskPermissions.tickets.update.type) &&
                permissionCheck(HelpdeskPermissions.tickets.delete.task, HelpdeskPermissions.tickets.delete.type)
            );
            setCanUpdateTickets(
                permissionCheck(HelpdeskPermissions.tickets.update.task, HelpdeskPermissions.tickets.update.type)
            );
            setCanDeleteTickets(
                permissionCheck(HelpdeskPermissions.tickets.delete.task, HelpdeskPermissions.tickets.delete.type)
            );
        },
        [tickets]
    );

    const handleViewTicket = (ticket, index) => {
        setDialogTicket(ticket);
        setOpenDialog(true);
    }

    const handleCloseTicket = async (ticket, index) => {
        await handleTicketStatusChange('closed', ticket);
    }

    const handleDeleteTicket = async (ticket, index) => {
        let result = await deleteHelpdeskTicket(ticket._id);
        if (result.success) {
            onTicketsChange && onTicketsChange(ticket, 'delete');
            setAlert('The Helpdesk Ticket was deleted successfully.', 'success');
        } else {
            setAlert('Could not delete the Helpdesk Ticket.', 'error');
        }
    }

    const handleTicketStatusChange = async (status, ticket = null) => {
        if (!ticket) {
            ticket = dialogTicket;
        }
        switch (status.toLowerCase()) {
            case 'rejected':
                setAlert('The Helpdesk ticket was marked as Rejected.', 'success');
                //alert('Reject '+ticket.toString());
            break;
            case 'resolved':
                setAlert('The Helpdesk ticket was marked as Resolved.', 'success');
                //alert('Resolve '+ticket.toString());
            break;
            default:
                setAlert('The Helpdesk ticket was closed successfully.', 'success');
                //alert('Close '+ticket.toString());
            break;
        }

        let result = await closeHelpdeskTicket(ticket.ticket_id, status.toUpperCase());
        console.log('ticket status change result = %o', result);

        onTicketsChange && onTicketsChange(ticket, 'close');
    }


    const handleCloseDialog = async (evt) => {
        setOpenDialog(false);
    }


    const handleReply = async (evt, newEntryText) => {
        let helpRequest = {
            action: 'reply',
            sim_id: dialogTicket.sim_id,
            level_id: dialogTicket.sim_level_id,
            script: -1,
            message: newEntryText,
        }
        console.log('Help Request: %o', helpRequest);

        let result = await addHelpdeskTicketEntry(dialogTicket.ticket_id, helpRequest);
        if (result && result.success) {
            let entries = dialogTicket ? dialogTicket.entries : [];
            entries.push({
                action: 'reply',
                message: newEntryText,
                created_by: {
                    _id: user._id.toString(),
                    full_name: user.first_name + ' ' + user.last_name,
                },
                creator_picture_url: user.profile_pic_url,
                creator_name: user.first_name + ' ' + user.last_name,
                creator_role: user.role,
                created_at: new Date(),
            });
            setDialogTicket({...dialogTicket, entries});
            setAlert('The Helpdesk ticket was updated successfully.', 'success');
            //alert('Ticket Updated');
        } else {
            setAlert('The message could not be added to the Helpdesk ticket.', 'error');
            //alert('The ticket was NOT updated');
            console.error(result);
        }
    }

    const showAction = (row, index) => {
        return (
            <TableCell key={`actions_${row.id}`} style={{minWidth: '12ch'}}>
                <div className={classes.completedActionCell}>
                    {canViewTickets ? (
                        <Tooltip title="View ticket's details">
                            <span>
                                <Icon
                                    variant="contained"
                                    color="primary"
                                    className={classes.viewRetryButton}
                                    onClick={() => handleViewTicket(row, index)}
                                >
                                    <VisibilityIcon />
                                </Icon>
                            </span>
                        </Tooltip>
                    ) : null}
                    {((['RESOLVED', 'REJECTED'].indexOf(row.status) < 0) && canCloseTickets) ? (
                        <Tooltip title="Close this ticket">
                            <span>
                                <Icon
                                    variant="contained"
                                    color="primary"
                                    className={classes.viewRetryButton}
                                    onClick={() => handleCloseTicket(row, index)}
                                >
                                    <CloseIcon />
                                </Icon>
                            </span>
                        </Tooltip>
                    ) : null}
                    {canDeleteTickets && (
                        <Tooltip title={<>
                            <Typography>
                                Delete this ticket.
                                <br />
                                <strong>
                                    <span style={{color: "red"}}>WARNING</span> - This can NOT be undone
                                </strong>
                            </Typography>
                        </>}>
                            <span>
                                <Icon
                                    variant="contained"
                                    color="primary"
                                    className={classes.viewRetryButton}
                                    onClick={() => handleDeleteTicket(row, index)}
                                >
                                    <DeleteIcon />
                                </Icon>
                            </span>
                        </Tooltip>
                    )}
                </div>
            </TableCell>
        );
    }

    const handleLinkClick = async (evt, simId) => {
        await setEditSimStep();
        history.push(`/sims/createsim?simId=${simId}`, dialogTicket);
    }

    const getBackgroundColor = (row) => {
        let rowBackground = '';
        switch (row.status) {
            case 'PENDING':
                rowBackground = 'rgba(255, 200, 200, 0.5)';
                break;
            case 'CLOSED':
            case 'RESOLVED':
                rowBackground = 'rgba(129, 255, 129, 0.95)';
                break;
            case 'REJECTED':
                rowBackground = 'rgba(255, 128, 128, 0.95)';
                break;
            case 'ARCHIVED':
                rowBackground = 'rgba(200, 200, 200, 0.5)';
                break;
            case 'IN PROGRESS':
            default:
                rowBackground = 'rgba(255, 255, 200, 0.5)';
                break;
        }

        return rowBackground;
    }

    return (
        <>
            <Table aria-label="Help requests" className={classes.table}>
                <TableHead className={classes.thead}>
                    <TableRow>
                        <TableCell className={classes.heading}
                                   sortDirection={orderBy === 'creator' ? order : false}
                        >
                            <TableSortLabel direction={orderBy === 'creator' ? order : 'asc'}
                                            active
                                            IconComponent={ArrowDropDownIcon}
                                            onClick={() => onSort('creator')}
                            >
                                INITIATED BY
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.heading}
                                   sortDirection={orderBy === 'status' ? order : false}
                        >
                            <TableSortLabel direction={orderBy === 'status' ? order : 'asc'}
                                            active
                                            IconComponent={ArrowDropDownIcon}
                                            onClick={() => onSort('status')}
                            >
                                STATUS
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.heading}
                                   sortDirection={orderBy === 'sim' ? order: false}
                        >
                            <TableSortLabel direction={orderBy === 'sim' ? order: 'asc'}
                                            active
                                            IconComponent={ArrowDropDownIcon}
                                            onClick={() => onSort('sim')}
                            >
                                SIM / ASSIGNMENT / PAGE
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.heading}
                                   sortDirection={orderBy === 'level' ? order: false}
                        >
                            <TableSortLabel direction={orderBy === 'level' ? order: 'asc'}
                                            active
                                            IconComponent={ArrowDropDownIcon}
                                            onClick={() => onSort('level')}
                            >
                                LEVEL
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.heading}
                                   sortDirection={orderBy === 'script' ? order: false}
                        >
                            <TableSortLabel direction={orderBy === 'script' ? order: 'asc'}
                                            active
                                            IconComponent={ArrowDropDownIcon}
                                            onClick={() => onSort('script')}
                            >
                                SCRIPT
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.heading}
                                   sortDirection={orderBy === 'created_at' ? order: false}
                        >
                            <TableSortLabel direction={orderBy === 'created_at' ? order: 'asc'}
                                            active
                                            IconComponent={ArrowDropDownIcon}
                                            onClick={() => onSort('created_at')}
                            >
                                INITIATED ON
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.heading}
                                   sortDirection={orderBy === 'owner' ? order: false}
                        >
                            <TableSortLabel direction={orderBy === 'owner' ? order: 'asc'}
                                            active
                                            IconComponent={ArrowDropDownIcon}
                                            onClick={() => onSort('owner')}
                            >
                                ASSIGNED TO
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.heading}>
                            STORY
                        </TableCell>
                        <TableCell>
                            <p>
                                ACTIONS
                            </p>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tbody}>
                    {tickets.map((row, index) => {
                        let rowBackground = getBackgroundColor(row);
                        return (
                            <TableRow key={index}
                                      className={classes.tableRow}
                                      style={rowBackground ? {backgroundColor: rowBackground} : null}
                            >
                                <TableCell>
                                    <div className={classes.built}>
                                        <Avatar src={row.created_by.profile_picture_url ?? ''}
                                                alt="profile"
                                                style={{marginRight: "10px"}}
                                                className={classes.small}
                                        />
                                        <div className={classes.name}>{row.created_by.full_name}</div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {row.status}
                                </TableCell>
                                <TableCell>
                                    {row.sim ? (
                                        <><strong>SIM</strong> {row.sim?.title}{row.assignment && (<p><strong>Assignment [{row.assignment.status}]</strong></p>)}</>
                                    ) : (
                                        <><strong>Page</strong> {row.page}</>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {isNaN(row.level?.order) ? (
                                        <>N/A</>
                                    ) : (
                                        <>{row.level?.order+1} - {row.level?.name}</>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {row.script ? (
                                        <>{row.script?.order} - {row.script?.hint_text}</>
                                    ) : (
                                        <>N/A</>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {dateFormat(row.created_at)}
                                </TableCell>
                                <TableCell>
                                    <div className={classes.built}>
                                        {row.assigned_to._id ? (
                                            <>
                                                <Avatar src={row.assigned_to.profile_picture_url ?? ''}
                                                        alt="profile"
                                                        style={{marginRight: "10px"}}
                                                        className={classes.small}
                                                />
                                                <div className={classes.name}>{row.assigned_to.full_name}</div>
                                            </>
                                        ) : (
                                            <p>N/A</p>
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <Conversation helpdeskTicket={row} maxEntries={1} onExpand={() => handleViewTicket(row, index)} />
                                </TableCell>
                                {showAction(row, index)}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            <TicketDetails ticket={dialogTicket}
                openDetails={openDialog}
                onClose={handleCloseDialog}
                onTicketStatusChange={handleTicketStatusChange}
                onReply={handleReply}
                canCloseTickets={canCloseTickets}
                canUpdateTickets={canUpdateTickets}
                onLinkClick={handleLinkClick}
                user={user}
            />

        </>

    );
}


HelpRequestTable.propTypes = {
    tickets: PropTypes.array,
    onSort: PropTypes.func,
    orderBy: PropTypes.string,
    order: PropTypes.string,
};
const mapStateToProps = (state) => ({
    tickets: state.helpdesk.helpdeskTickets ?? [],
});

const mapDispatchProps = {
    fetchHelpdeskTickets,
    setEditSimStep,
};

export default connect(mapStateToProps, mapDispatchProps)(HelpRequestTable);
