import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Button,
    List,
    ListItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import GetAppIcon from '@material-ui/icons/GetApp';
import ResponsiveDrawerStyle from './ResponsiveDrawer';
import { saveCaptivateWindowRef } from '../../redux/sims/actions';


const DownloadButtons = ({ showBtn, handleBtnToggle, saveCaptivateWindowRef }) => {
    const useStyles = makeStyles((theme) => ResponsiveDrawerStyle(theme));
    const theme = useTheme();
    const classes = useStyles();
    const location = useLocation();
    const [openDocuments, setOpenDocuments] = React.useState(false);
    const [openLinks, setOpenLinks] = React.useState(false);
    const [openCaptivateDemo, setOpenCaptivateDemo] = React.useState(false);
    let captivateLearningWindow;

    const openCaptivateNewWindow = () => {
        const url = location.state.captivate_learning_url;
        // call captivateLearningWindow.close() to close the created window.
        captivateLearningWindow = window.open(url,
            '_blank',
            'toolbar=yes, scrollbars=yes, resizable=yes,top=500, left=500, width=900, height=900, titlebar=no, toolbar=no, location=no');
        saveCaptivateWindowRef(captivateLearningWindow);
    };
    return (
        <>
            <Hidden className={classes.hidden} smUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'bottom' : 'top'}
                    open={showBtn}
                    onClose={handleBtnToggle}
                    classes={{
                        paper: classes.drawerBtnPaper,
                    }}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <List className={classes.list}>
                        {location.state.captivate_learning_video_url
                            && (
                                <ListItem
                                    className={classes.listItem}
                                    onClick={() => setOpenCaptivateDemo(true)}
                                >
                                    CAPTIVATE DEMO
                                </ListItem>
                            )}
                        {location.state.captivate_learning_url
                            && (
                                <ListItem
                                    className={classes.listItem}
                                    onClick={openCaptivateNewWindow}
                                >
                                    CAPTIVATE LEARNING
                                </ListItem>
                            )}
                        {location.state.documents && location.state.documents.length
                            ? (
                                <ListItem
                                    className={classes.listItem}
                                    onClick={() => setOpenDocuments(true)}
                                >
                                    DOWNLOAD DOCUMENTS
                                </ListItem>
                            )
                            : ''}
                        {location.state.hotLinks && location.state.hotLinks.length
                            ? (
                                <ListItem
                                    className={classes.listItem}
                                    onClick={() => setOpenLinks(true)}
                                >
                                    HOT LINK URL(s)
                                </ListItem>
                            )
                            : '' }
                    </List>
                </Drawer>
            </Hidden>
            {!showBtn
                && (
                    <div className={classes.downloadBtn}>
                        {location.state.captivate_learning_video_url
                            && (
                                <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    className={classes.downloadButtons}
                                    onClick={() => setOpenCaptivateDemo(true)}
                                >
                                    CAPTIVATE DEMO
                                </Button>
                            )}
                        {location.state.captivate_learning_url
                            && (
                                <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    className={classes.downloadButtons}
                                    onClick={openCaptivateNewWindow}
                                >
                                    CAPTIVATE LEARNING
                                </Button>
                            )}
                        {location.state.documents && location.state.documents.length
                            ? (
                                <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    className={classes.downloadButtons}
                                    onClick={() => setOpenDocuments(true)}
                                >
                                    DOWNLOAD DOCUMENTS
                                </Button>
                            )
                            : ''}
                        {location.state.hotLinks && location.state.hotLinks.length
                            ? (
                                <Button
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    className={classes.downloadButtons}
                                    onClick={() => setOpenLinks(true)}
                                >
                                    Hot Link URL(s)
                                </Button>
                            )
                            : ''}
                    </div>
                )}

            <Dialog
                open={openDocuments}
                onClose={() => { setOpenDocuments(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Download Documents</DialogTitle>
                <DialogContent>
                    <List>
                        {location.state.documents && location.state.documents.map((doc, index) => (
                            <ListItem key={doc._id}>
                                <ListItemText>
                                    {index + 1}
                                    .
                                    {' '}
                                    {doc.name}
                                </ListItemText>
                                <ListItemSecondaryAction className={classes.listAction}>
                                    <IconButton href={doc.document_url} target="_blank">
                                        {' '}
                                        {' '}
                                        <GetAppIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDocuments(false)}
                        className={classes.cancelButton}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openLinks}
                onClose={() => { setOpenLinks(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Hot Link URL(s)</DialogTitle>
                <DialogContent>
                    <List>
                        {location.state.hotLinks && location.state.hotLinks.map((link, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <ListItem key={index} component="a" href={link} target="_blank">
                                <ListItemText title={link.length > 55 ? link : null}>
                                    {index + 1}
                                    .
                                    {' '}
                                    {link.length > 55
                                        ? `${link.slice(0, 55)}  ...`
                                        : link}
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenLinks(false)}
                        className={classes.cancelButton}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openCaptivateDemo}
                onClose={() => { setOpenCaptivateDemo(false); }}
                maxWidth="lg"
            >
                <DialogTitle>Captivate Learning Demo Video</DialogTitle>
                <DialogContent>
                    <video
                        width="1000"
                        height="700"
                        controls
                        autoPlay
                    >
                        <source src={location.state.captivate_learning_video_url} type="video/mp4" />
                    </video>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenCaptivateDemo(false)}
                        className={classes.cancelButton}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
DownloadButtons.propTypes = {
    handleBtnToggle: PropTypes.func,
    showBtn: PropTypes.bool,
    saveCaptivateWindowRef: PropTypes.func,
};

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
    saveCaptivateWindowRef,
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButtons);
