export const DragDropArea = (theme) => ({
    dragDrop: {
        background: "inherit",
        border: "1px solid #CDCDCD",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "space-between",
        alignItems: "stretch",
        width: "100%",

        "&:hover": {
            borderColor: "#333",
        },

        "&.active" : {
            borderColor: "#6dc24b",
        },
    },
    uploadInfo : {
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",

        svg : {
            fontSize: "36px",
            marginRight: "1rem",
        },

        div : {
            p : {
                margin: "0",
                fontSize: "1rem",
            },

            "p:first-child" : {
                fontWeight: "bold",
                fontSize: "1.1em",
            },
        },
    },
    fileList : {
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
        width: "100%",
        minHeight: "3rem",
        flex: "1 1 75%",
        overflowY: "visible",
        overflowX: "overlay",
        padding: "0",
    },
    fileItem : {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "0.5rem",
        border: "1px solid transparent",
        borderRadius: "4px",
        alignSelf: "flex-start",
        justifySelf: "flex-start",
        alignContent: "flex-start",
        width: "100%",
        height: "100%",
        flex: "1",
        flexDirection: "column",

        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            border: "1px solid #BABABA",
        },
    },
    fileInfo :  {
        display: "flex",
        flexDirection: "column",
        gap: "0.25rem",
        flex: "1",
        minWidth: "24ch",
        "& p" : {
            margin: "0",
            fontSize: "1rem",
            color: "#333",
        },
    },
    fileActions : {
        cursor: "pointer",
        position: "absolute",
        top: "100%",
        left: "100%",
        transform: "translate(-100%, -100%)",
        width: "fit-content",
        "& svg" : {
            fontSize: "1em",
            color: "#888",
        },
        "&:hover svg" : {
            color: "#d44",
        },
    },
    "[class*='makeStyles-fileItem']:first-of-type": {
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
    },
    "[class*='makeStyles-fileItem']:last-of-type": {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
    },
    ".browse-btn": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0.5rem 1rem",
        border: "1px solid var(--border-color)",
        borderRadius: "8px",
        cursor: "pointer",
        backgroundColor: "var(--primary-color)",
        "&:hover":  {
            backgroundColor: "transparent",
        },
    },
    ".success-file" : {
        display: "flex",
        alignItems: "center",
        color: "#6dc24b",
        p : {
            margin: "0",
            fontSize: "14px",
            fontWeight: "bold",
        },
    },
    "input[type='file']":  {
        display: "none",
    },
});

export default DragDropArea;
