import React from 'react';
import {
    Grid, Card, CardContent, Avatar,
    Paper, Tooltip,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { permissionCheck } from '../../utils/permissionCheck';
import ProfilePic from '../../assets/images/profile_pic.png';
import {dayTime} from "../../utils/i18n";
import HelpdeskDataStyle from "./HelpdeskData";
import HelpdeskPermissions from '../../permissions/helpdesk';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HiddenIcon from "@material-ui/icons/VisibilityOff";

const HelpdeskData = ({
    ticketData,
    countData,
    ticketFilters,
    setTicketFilters
}) => {
    const useStyles = makeStyles((theme) => HelpdeskDataStyle(theme));
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem('user'));
    let time = dayTime();

    const toggleFilter = (filter) => {
        setTicketFilters({...ticketFilters, [filter]: !ticketFilters[filter]});
    }

    return (
        <Paper elevation={0}>
            <Card className={classes.back}>
                <CardContent className={classes.cardPadding}>
                    <Grid container spacing={5}>
                        <Grid item xs={3}>
                            <Avatar
                                height={47}
                                width={46}
                                alt="profile"
                                className={classes.avatar}
                                src={user.profile_pic_url ? user.profile_pic_url : ProfilePic}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <span className={classes.time}>Good {' '} {time}!</span>
                            <br />
                            {/*What's the point of this permission check? It's the current user's name what is being displayed*/}
                            <b className={classes.name}>{`${user.first_name} ${user.last_name}`}</b>
                            {/*
                            <b className={classes.name}>
                                {permissionCheck(
                                    DashboardPermissions.user_name.view.task,
                                    DashboardPermissions.user_name.view.type,
                                ) ? `${user.first_name} ${user.last_name}` : ''}
                            </b>*/}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Grid item xs={12} md={12}>
                <Box pt={3} pl={2} className={classes.company}>
                    Helpdesk Tickets
                </Box>
                {permissionCheck(
                    HelpdeskPermissions.tickets.view.task,
                    HelpdeskPermissions.tickets.view.type,
                )
                    ? (
                        <List>
                            <ListItem>
                                <ListItemIcon className={classes.toggle} onClick={() => toggleFilter('unassigned')}>
                                    {ticketFilters.unassigned ? (
                                        <Tooltip title="Click to hide unassigned tickets"><VisibilityIcon /></Tooltip>
                                    ) : (
                                        <Tooltip title="Click to show unassigned tickets"><HiddenIcon /></Tooltip>
                                    )}
                                </ListItemIcon>
                                <ListItemText className={classes.listText}>Unassigned Tickets</ListItemText>
                                <ListItemSecondaryAction className={ticketData?.unassigned ? classes.needsReview : classes.default}><b>{ticketData?.unassigned ?? '0'}</b></ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="middle" />
                            <ListItem>
                                <ListItemIcon className={classes.toggle} onClick={() => toggleFilter('inProgress')}>
                                    {ticketFilters.inProgress ? (
                                        <Tooltip title="Click to hide tickets in progress"><VisibilityIcon /></Tooltip>
                                    ) : (
                                        <Tooltip title="Click to show tickets in progress"><HiddenIcon /></Tooltip>
                                    )}
                                </ListItemIcon>
                                <ListItemText className={classes.listText}>Tickets In Progress</ListItemText>
                                <ListItemSecondaryAction className={ticketData?.inProgress ? classes.inProgress : classes.default}><b>{ticketData?.inProgress ?? '0'}</b></ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="middle" />
                            <ListItem>
                                <ListItemIcon className={classes.toggle} onClick={() => toggleFilter('pending')}>
                                    {ticketFilters.pending ? (
                                        <Tooltip title="Click to hide pending tickets"><VisibilityIcon /></Tooltip>
                                    ) : (
                                        <Tooltip title="Click to show pending tickets"><HiddenIcon /></Tooltip>
                                    )}
                                </ListItemIcon>
                                <ListItemText className={classes.listText}>Pending Tickets</ListItemText>
                                <ListItemSecondaryAction className={ticketData?.pending ? classes.pending : classes.default}><b>{ticketData?.pending ?? '0'}</b></ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="middle" />
                            <ListItem>
                                <ListItemIcon className={classes.toggle} onClick={() => toggleFilter('resolved')}>
                                    {ticketFilters.resolved ? (
                                        <Tooltip title="Click to hide resolved tickets"><VisibilityIcon /></Tooltip>
                                    ) : (
                                        <Tooltip title="Click to show resolved tickets"><HiddenIcon /></Tooltip>
                                    )}
                                </ListItemIcon>
                                <ListItemText className={classes.listText}>Resolved Tickets</ListItemText>
                                <ListItemSecondaryAction className={ticketData?.resolved ? classes.resolved : classes.default}><b>{ticketData?.resolved ?? '0'}</b></ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="middle" />
                            <ListItem>
                                <ListItemIcon className={classes.toggle} onClick={() => toggleFilter('rejected')}>
                                    {ticketFilters.rejected ? (
                                        <Tooltip title="Click to hide rejected tickets"><VisibilityIcon /></Tooltip>
                                    ) : (
                                        <Tooltip title="Click to show rejected tickets"><HiddenIcon /></Tooltip>
                                    )}
                                </ListItemIcon>
                                <ListItemText className={classes.listText}>Rejected Tickets</ListItemText>
                                <ListItemSecondaryAction  className={ticketData?.rejected ? classes.rejected : classes.default}><b>{ticketData?.rejected ?? '0'}</b></ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="middle" />
                            <ListItem>
                                <ListItemIcon className={classes.toggle} onClick={() => toggleFilter('archived')}>
                                    {ticketFilters.archived ? (
                                        <Tooltip title="Click to hide archived tickets"><VisibilityIcon /></Tooltip>
                                    ) : (
                                        <Tooltip title="Click to show archived tickets"><HiddenIcon /></Tooltip>
                                    )}
                                </ListItemIcon>
                                <ListItemText className={classes.listText}>Archived Tickets</ListItemText>
                                <ListItemSecondaryAction className={ticketData?.archived ? classes.archived : classes.default}><b>{ticketData?.archived ?? '0'}</b></ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="middle" />
                            <ListItem>
                                <ListItemText
                                    className={classes.listText}
                                    primary="Total Tickets"
                                />
                                <ListItemSecondaryAction className={classes.default}><b>{ticketData?.total ?? '0'}</b></ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    )
                    : ''}
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={12} md={12}>
                <Box pt={3} pl={2} className={classes.company}>Assignments</Box>
                <List>
                    <ListItem>
                        <Grid container className={classes.completed}>
                            <Grid item xs={8} className={classes.text}>
                                Completed
                                {' '}
                                <br />
                                {' '}
                                Assignments
                            </Grid>
                            <Grid item xs={4} className={classes.rect}>
                                <span className={classes.textCompleted}>
                                  {countData && countData.completedAssignmentCount >= 0
                                      ? `${countData ? countData.completedAssignmentCount : 0}/${countData ? countData.myAssignmentCount + countData.completedAssignmentCount : 0}`
                                      : '/'}
                                    {' '}
                                </span>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container className={classes.review}>
                            <Grid item xs={8} className={classes.text}>
                                Ready
                                {' '}
                                <br />
                                for Review
                            </Grid>
                            <Grid item xs={4} className={classes.rect}>
                                <span className={classes.textReview}>
                                  {countData.readyForReviewCount}
                                </span>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            </Grid>
        </Paper>
    );
};

HelpdeskData.propTypes = {
    ticketData: PropTypes.object,
    countData: PropTypes.object,
    ticketFilters: PropTypes.object,
    setTicketFilters: PropTypes.func,
};
export default HelpdeskData;
