import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {ListItemAvatar, Tooltip} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import {dateFormat} from "../../utils/i18n";

const Conversation = ({ helpdeskTicket, maxEntries, expanded, onExpand }) => {

    const user = JSON.parse(localStorage.getItem('user'));

    const formatMessage = (message) => {
        if (expanded || (message.length < 40)) {
            return message;
        }

        return message.substring(0, 37)+' ...';
    }

    const messageSection = (item, alternate) => {
        if (expanded || (item.message.length < 40)) {
            return (
                <ListItemText primary={formatMessage(item.message)} secondary={
                    <React.Fragment>
                        <Typography
                            component="small"
                            variant="body2"
                            sx={{ color: 'text.primary', display: 'inline' }}
                        >
                            {item.creator_name}
                        </Typography>
                        <br />
                        {dateFormat(item.created_at)}
                    </React.Fragment>}
                    style={alternate && {textAlign: 'right'}}
                />
            );
        }

        console.log('Conversation Item = %o', item);
        let extraStyle = {
            typography: 'body1'
        };
        if (alternate) {
            extraStyle.textAlign = 'right';
        }
        return (
            <Tooltip title={item.message} sx={extraStyle} style={extraStyle}>
                <ListItemText primary={formatMessage(item.message)} secondary={
                    <React.Fragment>
                        <Typography
                            component="small"
                            variant="body2"
                            sx={{ color: 'text.primary', display: 'inline' }}
                        >
                            {item.creator_name}
                            <br />
                            <small>{dateFormat(item.created_at)}</small>
                        </Typography>
                    </React.Fragment>}
                    style={alternate && {textAlign: 'right'}}
                />
            </Tooltip>
        )
    }

    const avatarSection = (item, alternate) => {
        console.log('Avatar URL = %s', item.creator_picture_url);
        return (
            <ListItemAvatar style={{color: 'text.primary', display: 'block', textAlign: 'center', margin: '0 auto'} }>
                <Avatar src={item.creator_picture_url}
                        style={alternate && {marginLeft: '1em', marginRight: 0, margin: '0 auto'}}
                />
                <Typography
                    component="small"
                    variant="body2"
                    style={{textAlign: 'center', margin: '0 auto'}}
                >
                    <small>{item.action}</small>
                </Typography>
            </ListItemAvatar>
        )
    }

    if (!helpdeskTicket.entries || !helpdeskTicket.entries.length) {
        return (<></>);
    }

    if (!maxEntries || (maxEntries === -1)) {
        maxEntries = helpdeskTicket.entries.length;
    }

    return (
        <List flex="true" style={{display: 'flex', flexDirection: 'column', gap: '0.25em', maxHeight: '50vh', overflowY: 'auto'}}>
            {helpdeskTicket.entries.slice(0, maxEntries).map((item, idx) => {
                let itemId = `${helpdeskTicket.ticket_id}-${idx}-${Date.now()}${Date.now()*Math.E}`;
                let creatorId = item?.created_by?._id?.toString() ?? item?.created_by;
                let alternate = creatorId !== user._id.toString();//dx && (idx % 2));
                if (alternate) {
                    let style = {
                        textAlign: 'right',
                        borderRadius: '10px 10px 0 10px',
                        border: '1px solid rgba(128, 128, 128, 0.25)',
                        gap: '1em',
                    };
                    if (expanded) {
                        style.backgroundColor = 'rgba(220, 220, 220, 0.25)';
                    }
                    return (
                        <ListItem alignItems="flex-start"
                            style={style}
                            id={itemId}
                        >
                            {messageSection(item, true)}
                            {avatarSection(item, true)}
                        </ListItem>
                    );
                }
                return (
                    <ListItem alignItems="flex-start"
                        style={{borderRadius: '10px 10px 10px 0', border: '1px solid rgba(128, 128, 128, 0.25)', gap: '1em'}}
                        id={itemId}
                    >
                        {avatarSection(item)}
                        {messageSection(item)}
                    </ListItem>
                )
            })}
            { ((helpdeskTicket?.entries?.length ?? 0) > maxEntries) && (
                <ListItem id={`${helpdeskTicket.ticket_id}--1--${Date.now()}`} onClick={onExpand}>
                    ... {helpdeskTicket?.entries?.length - maxEntries} more messages
                </ListItem>
            )}
        </List>
    );
};

Conversation.propTypes = {
    helpdeskTicket: PropTypes.object,
    maxEntries: PropTypes.number,
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Conversation);
