import background from '../../assets/images/Profilebg.png';

export const HelpdeskDataStyle = (theme) => ({
    back: {
        backgroundSize: 'cover',
        height: 100,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${background})`,
    },
    company: {
        fontSize: 16,
        fontWeight: 600,
    },
    divider: {
        borderTop: '1px dashed rgba(0, 0, 0, 0.12)',
        background: '#ffffff',
        marginTop: 5,
    },
    total: {
        height: 80,
        borderRadius: 5,
        alignItems: 'center',
        padding: '0px 15px',
        background: 'rgb(237,245,254)',
    },
    completed: {
        background: 'rgb(238,246,237)',
        height: 80,
        borderRadius: 5,
        alignItems: 'center',
        padding: '0px 10px',
    },
    review: {
        height: 80,
        borderRadius: 5,
        alignItems: 'center',
        padding: '0px 15px',
        background: 'rgb(252,234,241)',
    },
    rect: {
        fontSize: 17,
        borderRadius: 5,
        height: '50%',
        fontWeight: 600,
        background: '#ffffff',
        width: '25%',
        textAlign: 'center',
        paddingTop: 9,
    },
    text: {
        fontWeight: 600,
        fontFamily: 'Proxima Nova',
        fontSize: 14,
        color: '#292828db',
    },
    head: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '15%',
        fontSize: 25,
        transform: 'translate(0, -50%)',
    },
    time: {
        color: '#fff',
        fontWeight: 400,
        fontSize: 14,
    },
    name: {
        color: '#ffffff',
        fontSize: 18,
        whiteSpace: 'nowrap',
    },
    content: {
        paddingBottom: '15px !important',
    },
    textTotal: {
        color: 'rgb(71,93,119)',
    },
    textCompleted: {
        color: 'rgb(89,175,100)',
    },
    textReview: {
        color: 'rgb(235,82,102)',
    },
    '@keyframes blink': {
        '0%': {
            backgroundColor: 'rgb(235,82,102)',
        },
        '50%': {
            backgroundColor: 'inherit',
            color: 'rgb(235,82,102)',
            border: '1px solid rgb(235,82,102)',
        },
        '100%': {
            backgroundColor: 'rgb(235,82,102)',
        },
    },
    needsReview: {
        backgroundColor: 'rgb(235,82,102)',
        color: 'white',
        '&.MuiListItemSecondaryAction-root': {
            borderRadius: '50%',
            width: '2em',
            height: '2em',
            textAlign: 'center',
            padding: '0.25em 0.5em',
            animation: '$blink 2.5s infinite ease-in-out',
        },
    },
    pending: {
        backgroundColor: 'rgba(255, 200, 200, 0.5)',
        borderRadius: '50%',
        width: '2em',
        height: '2em',
        textAlign: 'center',
        '&.MuiListItemSecondaryAction-root': {
            padding: '0.25em 0.5em',
        },
    },
    resolved: {
        backgroundColor: 'rgba(129, 255, 129, 0.95)',
        borderRadius: '50%',
        width: '2em',
        height: '2em',
        textAlign: 'center',
        '&.MuiListItemSecondaryAction-root': {
            padding: '0.25em 0.5em',
        },
    },
    rejected: {
        backgroundColor: 'rgba(255, 128, 128, 0.95)',
        borderRadius: '50%',
        width: '2em',
        height: '2em',
        textAlign: 'center',
        '&.MuiListItemSecondaryAction-root': {
            padding: '0.25em 0.5em',
        },
    },
    archived: {
        backgroundColor: 'rgba(200, 200, 200, 0.5)',
        borderRadius: '50%',
        width: '2em',
        height: '2em',
        textAlign: 'center',
        '&.MuiListItemSecondaryAction-root': {
            padding: '0.25em 0.5em',
        },
    },
    open: {
        backgroundColor: 'rgba(255, 255, 200, 0.5)',
        borderRadius: '50%',
        width: '2em',
        height: '2em',
        textAlign: 'center',
        '&.MuiListItemSecondaryAction-root': {
            padding: '0.25em 0.5em',
        },
    },
    inProgress: {
        backgroundColor: 'rgba(230, 255, 230, 0.85)',
        borderRadius: '50%',
        width: '2em',
        height: '2em',
        textAlign: 'center',
        '&.MuiListItemSecondaryAction-root': {
            padding: '0.25em 0.5em',
        },
    },
    default: {
        backgroundColor: 'inherit',
        borderRadius: '50%',
        width: '2em',
        height: '2em',
        textAlign: 'center',
        '&.MuiListItemSecondaryAction-root': {
            padding: '0.25em 0.5em',
        },
    },
    cardPadding: {
        paddingTop: 25,
    },
    avatar: {
        width: 48,
        border: '5px solid #354E7E',
        height: 50,
    },
    listText: {
        '& .MuiTypography-displayBlock': {
            fontSize: 14,
        },
    },
    toggle: {
        cursor: 'pointer',
        color: theme.palette.text.primary,
    }
});

export default HelpdeskDataStyle;
