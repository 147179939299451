import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect } from "react";
import {
    makeStyles,
    withStyles,
    createTheme,
} from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import { getAssignmentHistory } from '../../services/result.service';
import {
    Icon,
    ThemeProvider,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogContentText,
    DialogActions,
    CircularProgress,
    Paper,
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Delete from "../../assets/images/icons/Delete.png";
import Edit from "../../assets/images/icons/Edit.png";
import StyledLink from "../common/StyledLink";
import {
    handleShowReviewerButtons,
    saveAssignmentNotes,
    deleteAssignment,
    saveCurrentSimProductType
} from "../../redux/sims/actions";
import DashboardPermissions from "../../permissions/dashboard";
import { permissionCheck } from "../../utils/permissionCheck";
import EventNoteIcon from "@material-ui/icons/EventNote";
import noAssignments from "../../assets/images/noAssignments.png";
import SimsPermissions from "../../permissions/sims";
import AssignmentTableStyle from './AssignmentTable';
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import VideocamIcon from "@material-ui/icons/Videocam";

const innerTheme = createTheme({
    palette: {
        primary: indigo,
    },
});
const StyledTableRow = withStyles((theme) => ({
    root: {
        // "&:nth-of-type(even)": {
        //   backgroundColor: theme.palette.action.hover,
        // },
    },
}))(TableRow);

const AssignmentTable = ({
    assignments,
    value,
    total,
    sortProperties,
    order,
    orderBy,
    handleShowReviewerButtons,
    inMyAssignmentTab,
    inReadyReviewTab,
    saveAssignmentNotes,
    deleteAssignment,
    refreshCount,
    addAssignment,
    assignment_loading,
    handlePageChange,
    page,
    saveCurrentSimProductType
}) => {
    const loggedUser = JSON.parse(localStorage.getItem('user'));

    const useStyles = makeStyles((theme) => AssignmentTableStyle(theme));
    const classes = useStyles();
    const history = useHistory();
    const [noteDialog, setNoteDialog] = React.useState(false);
    const [notes, setNotes] = React.useState("");
    const [noteStatus, setNoteStatus] = React.useState("view");
    const [assignmentId, setAssignmentId] = React.useState("");
    const [currentIndex, setCurrentIndex] = React.useState("");
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [passFailHistory, setPassFailHistory] = React.useState([]);
    const [trackRecordDialog , setTrackRecordDialog] = React.useState(false);

    const handleClick = (row) => {
        localStorage.setItem("sim_id", row.sim_id);
        history.push({
            pathname: "/sims",
            state: {...row, user_id: row.assigned_to_id},
        });
        saveCurrentSimProductType(row.tool_type);
    };

    useEffect(() => {
        console.log();
    }, [assignment_loading]);

    const handleClose = () => {
        setNoteDialog(false);
        setNotes("");
        setAssignmentId("");
        setCurrentIndex("");
        setDeleteDialog(false);
    };

    const addNotes = () => {
        const payload = {
            assignment_notes: notes,
        };
        saveAssignmentNotes(assignmentId, payload, false);
        setNoteDialog(false);
        setNotes("");
        setAssignmentId("");
        assignments[currentIndex].notes = notes;
    };

    const editNotes = () => {
        const payload = {
            assignment_notes: notes,
        };
        saveAssignmentNotes(assignmentId, payload, true);
        setNoteDialog(false);
        setNotes("");
        setAssignmentId("");
        assignments[currentIndex].notes = notes;
    };

    const removeAssignment = () => {
        setDeleteDialog(true);
    };

    const deleteConfirm = () => {
        assignments.splice(currentIndex, 1);
        deleteAssignment(assignmentId);
        setDeleteDialog(false);
        setAssignmentId("");
        setCurrentIndex("");
        refreshCount();
    };

    const trackRecord = (row) => {
        setTrackRecordDialog(true);
        getAssignmentHistory(row.assignment_id)
            .then((res) => {
                setPassFailHistory(res.data[0].reviewed_status.reverse());
            });
    };

    const userId = JSON.parse(localStorage.getItem("user"))._id;

    const handleTrackClose = () => {
        setTrackRecordDialog(false);
        setPassFailHistory([]);
    }

    const showLearnersMessage = (row) => {
        if (row.status === "COMPLETED") {
            return (
                <TableCell
                    className={`${userId !== row.assigned_to_id && row.status !== "PENDING REVIEW"
                        ? classes.disabled
                        : classes.adminNote
                    } ${row.status === "NO PASS" ? classes.failedTableRow : ""}`}
                >
                    ---------
                </TableCell>
            );
        } else {
            return (
                <TableCell
                    title={row.review_comments?.length > 90 ? row.review_comments : null}
                    className={`${userId !== row.assigned_to_id && row.status !== "PENDING REVIEW"
                        ? classes.disabled
                        : classes.adminNote
                    } ${row.status === "NO PASS" ? classes.failedTableRow : ""}`}
                >
                    {row?.review_comments?.length > 90
                        ? `${row.review_comments.slice(0, 90)}  ...`
                        : row.review_comments}
                </TableCell>
            );
        }
    };

    const showAction = (row, index) => {
        if (value === "myAssignment") {
            return (
                <TableCell key={`actions_${row.id}`}>
                    <div className={classes.assignmentActionCell}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => handleClick(row)}
                            endIcon={<ArrowRightAltIcon fontSize="small" />}
                        >
                            Go
                        </Button>
                    </div>
                </TableCell>
            );
        } else if (value === "completed") {
            return (
                <TableCell key={`actions_${row.id}`}>
                    <div className={classes.completedActionCell}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.viewRetryButton}
                            onClick={() => handleClick(row)}
                        >
                            VIEW OR RETRY
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.viewRetryButton}
                            onClick={() => trackRecord(row)}
                        >
                            TRACK RECORD
                        </Button>
                    </div>
                </TableCell>
            );
        } else if (value === "readyForReview") {
            return (
                <TableCell key={`actions_${row.id}`}>
                    <div className={classes.built}>
                        {row.status === "PENDING REVIEW" && (
                            <StyledLink
                                to={{
                                    pathname: "/sims/takesim",
                                    search: `?levelId=${row.sim_level_id}&assignment_id=${row.assignment_id}&sim_id=${row.sim_id}&user_id=${row.assigned_to_id}&order=${row.sim_level_order}`,
                                    state: {
                                        viewResult: true,
                                        // assignment: assignmentData,handleShowReviewerButtons
                                    },
                                }}
                            >
                                <Tooltip title={"Review"} placement="top">
                                    <VisibilityIcon
                                        onClick={() => handleShowReviewerButtons({ payload: true })}
                                        className={classes.viewIcon}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            </StyledLink>
                        )}
                    </div>
                </TableCell>
            );
        } else {
            return (
                <TableCell key={`actions_${row.id}`}>
                    <div className={classes.built}>
                        {!permissionCheck(
                            DashboardPermissions.notes_to_user.update.task,
                            DashboardPermissions.notes_to_user.update.type
                        ) &&
                        !permissionCheck(
                            DashboardPermissions.notes_to_user.create.task,
                            DashboardPermissions.notes_to_user.create.type
                        ) &&
                        permissionCheck(
                            DashboardPermissions.notes_to_user.view.task,
                            DashboardPermissions.notes_to_user.view.type
                        ) ? (
                            <ThemeProvider theme={innerTheme}>
                                <Tooltip title="View Instructions">
                                    <EventNoteIcon
                                        className={classes.viewNote}
                                        onClick={() => {
                                            setNoteDialog(true);
                                            setAssignmentId(row.assignment_id);
                                            setNotes(row.notes);
                                            setNoteStatus("view");
                                            setCurrentIndex(index);
                                        }}
                                    />
                                </Tooltip>
                            </ThemeProvider>
                        ) : permissionCheck(
                            DashboardPermissions.notes_to_user.update.task,
                            DashboardPermissions.notes_to_user.update.type
                        ) ||
                        permissionCheck(
                            DashboardPermissions.notes_to_user.create.task,
                            DashboardPermissions.notes_to_user.create.type
                        ) ? (
                            <ThemeProvider theme={innerTheme}>
                                <Tooltip title="Add Instructions">
                                    <Icon
                                        onClick={() => {
                                            setNoteDialog(true);
                                            setAssignmentId(row.assignment_id);
                                            setNotes(row.notes);
                                            setNoteStatus(row.notes === " " ? "add" : "edit");
                                            setCurrentIndex(index);
                                        }}
                                    >
                                        <img
                                            className={classes.edit}
                                            height={17}
                                            src={Edit}
                                            alt="edit"
                                        />
                                    </Icon>
                                </Tooltip>
                            </ThemeProvider>
                        ) : null}
                        {row.status === "NOT STARTED" ? (
                            <Tooltip
                                title={
                                    !permissionCheck(
                                        SimsPermissions.assignment.delete.task,
                                        SimsPermissions.assignment.delete.type
                                    )
                                        ? "You don't have permission to delete"
                                        : "Delete Assignment"
                                }
                            >
                                <Icon
                                    onClick={
                                        !permissionCheck(
                                            SimsPermissions.assignment.delete.task,
                                            SimsPermissions.assignment.delete.type
                                        )
                                            ? () => { }
                                            : () => {
                                                removeAssignment();
                                                setCurrentIndex(index);
                                                setAssignmentId(row.assignment_id);
                                            }
                                    }
                                    disabled={true}
                                >
                                    <img
                                        className={
                                            !permissionCheck(
                                                SimsPermissions.assignment.delete.task,
                                                SimsPermissions.assignment.delete.type
                                            )
                                                ? classes.disabledDelete
                                                : classes.delete
                                        }
                                        height={17}
                                        src={Delete}
                                        alt="delete"
                                        disabled
                                    />
                                </Icon>
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                        {row.status === "PENDING REVIEW" && (
                            <StyledLink
                                to={{
                                    pathname: "/sims/takesim",
                                    search: `?levelId=${row.sim_level_id}&assignment_id=${row.assignment_id}&sim_id=${row.sim_id}&user_id=${row.assigned_to_id}&reviewer=true&order=${row.sim_level_order}`,
                                    state: {
                                        viewResult: true,
                                        // assignment: assignmentData,handleShowReviewerButtons
                                    },
                                }}
                            >
                                <Tooltip title={"Review"} placement="top">
                                    <VisibilityIcon
                                        onClick={() => handleShowReviewerButtons({ payload: true })}
                                        style={{ color: "#882847", marginLeft: ".6rem" }}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            </StyledLink>
                        )}
                    </div>
                </TableCell>
            );
        }
    };

    const showAssignedTo = (['admin', 'account owner'].indexOf(loggedUser.role.toLowerCase()) > -1) ?
        (value.toLowerCase() !== 'myassignment'):
        ((['allassignment', 'completed'].indexOf(value.toLowerCase()) > -1) && (loggedUser._id !== userId));
    /*
    console.log('Assginments: %o', assignments);
    console.log('Logged User: %o', loggedUser);
    console.log('User Id: %o', userId);
    */

    return (
        <div>
            <Dialog
                onClose={handleClose}
                open={noteDialog}
                classes={{ paper: classes.dialog }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Instruction</DialogTitle>
                <DialogContent>
                    <div style={{ padding: "0.5rem", width: "23rem" }}>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                style: { fontSize: 14, paddingLeft: 8 },
                            }}
                            fullWidth
                            multiline
                            rows={5}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            className={classes.textArea}
                            placeholder="Type your note here"
                            disabled={
                                !permissionCheck(
                                    DashboardPermissions.notes_to_user.update.task,
                                    DashboardPermissions.notes_to_user.update.type
                                ) &&
                                !permissionCheck(
                                    DashboardPermissions.notes_to_user.create.task,
                                    DashboardPermissions.notes_to_user.create.type
                                ) &&
                                permissionCheck(
                                    DashboardPermissions.notes_to_user.view.task,
                                    DashboardPermissions.notes_to_user.view.type
                                )
                            }
                        />
                        <Button
                            size="small"
                            style={{
                                fontWeight: 600,
                                padding: "3px 11px",
                                backgroundColor: "#F8DEE7",
                                float: "right",
                                color: "#A55B73",
                                margin: "8px 0px",
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        {noteStatus === "edit" ? (
                            <Tooltip
                                title={
                                    !permissionCheck(
                                        DashboardPermissions.notes_to_user.update.task,
                                        DashboardPermissions.notes_to_user.update.type
                                    )
                                        ? "You don't have permission to update"
                                        : ""
                                }
                                placement="top"
                            >
                <span className={classes.editSpan}>
                  <Button
                      size="small"
                      className={classes.dialogButton}
                      disabled={
                          !permissionCheck(
                              DashboardPermissions.notes_to_user.update.task,
                              DashboardPermissions.notes_to_user.update.type
                          )
                      }
                      onClick={editNotes}
                  >
                    Edit
                  </Button>
                </span>
                            </Tooltip>
                        ) : noteStatus === "add" ? (
                            <Tooltip
                                title={
                                    !permissionCheck(
                                        DashboardPermissions.notes_to_user.create.task,
                                        DashboardPermissions.notes_to_user.create.type
                                    )
                                        ? "You don't have permission to create"
                                        : ""
                                }
                                placement="top"
                            >
                <span className={classes.editSpan}>
                  <Button
                      size="small"
                      className={classes.dialogButton}
                      disabled={
                          !permissionCheck(
                              DashboardPermissions.notes_to_user.create.task,
                              DashboardPermissions.notes_to_user.create.type
                          )
                      }
                      onClick={addNotes}
                  >
                    Add
                  </Button>
                </span>
                            </Tooltip>
                        ) : null}
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={deleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Assignment</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Do you want to delete the assignment "${assignments[currentIndex]?.assignment}"`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classes.cancelButton}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => deleteConfirm()}
                        className={classes.dialogDeleteBtn}
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={trackRecordDialog}
                onClose={handleTrackClose}
                className={classes.triesDialog}
            >
                <DialogTitle id="alert-dialog-title">Reviewed Result</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <TableContainer>
                        <Table className={classes.tableBorder}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell className={classes.tableCell}>REVIEW DATE</TableCell>
                                    <TableCell className={classes.tableCell}>RESULT</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    passFailHistory.length ?
                                        passFailHistory.map((pf, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{moment(pf.review_date).format('MM-DD-YYYY')}</TableCell>
                                                <TableCell>{pf.pass_or_fail}</TableCell>
                                            </TableRow>
                                        )) : <span className={classes.noRecord}>No previous results..</span>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTrackClose} className={classes.cancelButton}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {assignment_loading && assignments.length === 0 ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70vh",
                    }}
                >
                    <CircularProgress color="primary" />
                </div>
            ) : (
                <Paper>
                    <TableContainer className={classes.tableContainer}>
                        <Table aria-label="assignment table" className={classes.table}>
                            <TableHead className={classes.thead}>
                                <TableRow>
                                    {!showAssignedTo ? null : (
                                        <TableCell className={classes.heading} sortDirection={orderBy === 'assigned_to' ? order : false} >
                                            <TableSortLabel
                                                direction={orderBy === 'assigned_to' ? order : 'asc'}
                                                active
                                                // ={orderBy === 'assigned_to'}
                                                IconComponent={ArrowDropDownIcon}
                                                onClick={() => sortProperties('assigned_to')}
                                            >
                                                ASSIGNED TO
                                            </TableSortLabel>
                                        </TableCell>
                                    )}
                                    {value === "allAssignment" ? (
                                        <TableCell className={classes.heading} sortDirection={orderBy === 'group' ? order : false}>
                                            <TableSortLabel
                                                direction={orderBy === 'group' ? order : 'asc'}
                                                active
                                                // ={orderBy === 'group'}
                                                IconComponent={ArrowDropDownIcon}
                                                onClick={() => sortProperties('groups')}
                                            >
                                                GROUP
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : null}
                                    <TableCell className={classes.heading} sortDirection={orderBy === 'tool_type' ? order : false}>
                                        <TableSortLabel
                                            direction={orderBy === 'tool_type' ? order : 'asc'}
                                            active
                                            // ={orderBy === 'tool_type'}
                                            IconComponent={ArrowDropDownIcon}
                                            onClick={() => sortProperties('tool_type')}
                                        >
                                            TOOL TYPE
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={classes.heading} sortDirection={orderBy === 'assignment' ? order : false}>
                                        <TableSortLabel
                                            direction={orderBy === 'assignment' ? order : 'asc'}
                                            active
                                            // ={orderBy === 'assignment'}
                                            IconComponent={ArrowDropDownIcon}
                                            onClick={() => sortProperties('assignment')}
                                        >
                                            ASSIGNMENT
                                        </TableSortLabel>
                                    </TableCell>
                                    {/* <TableCell className={classes.heading}>
                <TableSortLabel
                  direction="desc"
                  active
                  //
                  IconComponent={ArrowDropDownIcon}
                >
                  LIMIT
                </TableSortLabel>
              </TableCell> */}
                                    <TableCell className={classes.heading} sortDirection={orderBy === 'assigned_on' ? order : false}>
                                        <TableSortLabel
                                            direction={orderBy === 'assigned_on' ? order : 'asc'}
                                            active
                                            // ={orderBy === 'assigned_on'}
                                            IconComponent={ArrowDropDownIcon}
                                            onClick={() => sortProperties('assigned_on')}
                                        >
                                            ASSIGNED ON
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={classes.heading} sortDirection={orderBy === 'assigned_by' ? order : false}>
                                        <TableSortLabel
                                            direction={orderBy === 'assigned_by' ? order : 'asc'}
                                            active
                                            // ={orderBy === 'assigned_by'}
                                            IconComponent={ArrowDropDownIcon}
                                            onClick={() => sortProperties('assigned_by')}
                                        >
                                            ASSIGNED BY
                                        </TableSortLabel>
                                    </TableCell>
                                    {value === "myAssignment" || value === "completed" || value === "allAssignment" ? (
                                        <TableCell className={classes.heading} sortDirection={orderBy === 'notes' ? order : false}>
                                            <TableSortLabel
                                                direction={orderBy === 'notes' ? order : 'asc'}
                                                active
                                                // ={orderBy === 'notes'}
                                                IconComponent={ArrowDropDownIcon}
                                                onClick={() => sortProperties('notes')}
                                            >
                                                INSTRUCTION
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : null}
                                    {value === "completed" ? (
                                        <TableCell className={classes.heading} sortDirection={orderBy === 'review_comments' ? order : false}>
                                            <TableSortLabel
                                                direction={orderBy === 'review_comments' ? order : 'asc'}
                                                active
                                                // ={orderBy === 'review_comments'}
                                                IconComponent={ArrowDropDownIcon}
                                                onClick={() => sortProperties('review_comments')}
                                            >
                                                MESSAGE TO LEARNER
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : null}
                                    {value === "completed" ? (
                                        <TableCell className={classes.heading} sortDirection={orderBy === 'start_date' ? order : false}>
                                            <TableSortLabel
                                                direction={orderBy === 'start_date' ? order : 'asc'}
                                                active
                                                // ={orderBy === 'start_date'}
                                                IconComponent={ArrowDropDownIcon}
                                                onClick={() => sortProperties('start_date')}
                                            >
                                                START DATE
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : null}
                                    {value === "completed" ? (
                                        <TableCell className={classes.heading} sortDirection={orderBy === 'send_for_review_date' ? order : false}>
                                            <TableSortLabel
                                                direction={orderBy === 'send_for_review_date' ? order : 'asc'}
                                                active
                                                // ={orderBy === 'send_for_review_date'}
                                                IconComponent={ArrowDropDownIcon}
                                                onClick={() => sortProperties('send_for_review_date')}
                                            >
                                                SENT FOR REVIEW DATE
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : null}
                                    {value === "completed" ? (
                                        <TableCell className={classes.heading} sortDirection={orderBy === 'date_of_review' ? order : false}>
                                            <TableSortLabel
                                                direction={orderBy === 'date_of_review' ? order : 'asc'}
                                                active
                                                // ={orderBy === 'date_of_review'}
                                                IconComponent={ArrowDropDownIcon}
                                                onClick={() => sortProperties('date_of_review')}
                                            >
                                                DATE OF REVIEW
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : null}
                                    {value === "readyForReview" ? null : (
                                        <TableCell className={classes.heading} sortDirection={orderBy === 'status' ? order : false}>
                                            <TableSortLabel
                                                direction={orderBy === 'status' ? order : 'asc'}
                                                active
                                                // ={orderBy === 'status'}
                                                IconComponent={ArrowDropDownIcon}
                                                onClick={() => sortProperties('status')}
                                            >
                                                STATUS
                                            </TableSortLabel>
                                        </TableCell>
                                    )}
                                    {value === "readyForReview" ? (
                                        <TableCell className={classes.heading} sortDirection={orderBy === 'send_for_review_date' ? order : false}>
                                            <TableSortLabel
                                                direction={orderBy === 'send_for_review_date' ? order : 'asc'}
                                                active
                                                // ={orderBy === 'send_for_review_date'}
                                                IconComponent={ArrowDropDownIcon}
                                                onClick={() => sortProperties('send_for_review_date')}
                                            >
                                                COMPLETED ON
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : null}
                                    <TableCell className={classes.heading}>ACTIONS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tbody}>
                                {assignments.map((row, index) => (
                                    <StyledTableRow
                                        key={index}
                                        className={
                                            row.status === "PENDING REVIEW" &&
                                            value === "allAssignment" &&
                                            !inReadyReviewTab
                                                ? classes.rfrTableRow
                                                : value === "myAssignment" ||
                                                value === "completed" ||
                                                value === "readyForReview"
                                                    ? classes.cell
                                                    : classes.tableRow
                                        }
                                    >
                                        {!showAssignedTo ? null : (
                                            <TableCell
                                                onClick={() => handleClick(row)}
                                            >
                                                <div className={classes.built}>
                                                    <Avatar
                                                        src={
                                                            row.assigned_profile_url
                                                                ? row.assigned_profile_url
                                                                : ""
                                                        }
                                                        alt="profile"
                                                        style={{ marginRight: "10px" }}
                                                        className={classes.small}
                                                    />
                                                    <div className={classes.name}>{row.assigned_to}</div>
                                                </div>
                                            </TableCell>
                                        )}
                                        {value === "allAssignment" ? (
                                            <TableCell
                                                // className={
                                                //   userId !== row.assigned_to_id &&
                                                //     row.status !== "PENDING REVIEW"
                                                //     ? classes.disabled
                                                //     : ""
                                                // }
                                                onClick={() => handleClick(row)}
                                            >
                                                { (row.groups.toString()).replace(/,/g, ', ')}
                                            </TableCell>
                                        ) : null}
                                        {value === "allAssignment" ? (
                                            <TableCell
                                                onClick={() => handleClick(row)}
                                                // className={
                                                //   userId !== row.assigned_to_id &&
                                                //     row.status !== "PENDING REVIEW"
                                                //     ? classes.disabled
                                                //     : ""
                                                // }
                                            >
                                                {row.tool_type}
                                            </TableCell>
                                        ) : (
                                            <TableCell
                                                className={`${userId !== row.assigned_to_id &&
                                                row.status !== "PENDING REVIEW"
                                                    ? classes.disabled
                                                    : ""
                                                } ${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                }`}
                                            >
                                                {row.tool_type}
                                            </TableCell>
                                        )}
                                        {value === "allAssignment" ? (
                                            <TableCell
                                                onClick={() => handleClick(row)}
                                                // className={
                                                //   userId !== row.assigned_to_id &&
                                                //     row.status !== "PENDING REVIEW"
                                                //     ? classes.disabled
                                                //     : classes.overflow
                                                // }
                                            >
                                                <Tooltip title={row?.assignment?.length === 0 ? 'None' : row.assignment}>
                                                    <>
                                                    {(row?.screen_recording?.length ) ?
                                                        (
                                                            <Tooltip title={'A level in this SIM allows screen share recordings'}
                                                                     placement="top"
                                                            >
                                                                <div style={{
                                                                    marginRight: '1em',
                                                                    verticalAlign: 'middle',
                                                                    display: 'inline-block',
                                                                }}>
                                                                    <ScreenShareIcon fontSize="small"/>
                                                                </div>
                                                            </Tooltip>
                                                        ) : (<></>)
                                                    }
                                                    {row?.screen_recording_urls?.length ? (
                                                        <Tooltip
                                                            title={`User has ${row.screen_recording_urls.length} screen recordings in one or more levels of this SIM`}
                                                            placement="top"
                                                        >
                                                            <div style={{
                                                                marginRight: '1em',
                                                                verticalAlign: 'middle',
                                                                display: 'inline-block',
                                                            }}>
                                                                <VideocamIcon fontSize="small" />
                                                            </div>
                                                        </Tooltip>
                                                    ) : (<></>)}
                                                    {row?.assignment?.length > 50
                                                        ? `${row.assignment.slice(0, 50)}  ...`
                                                        : row.assignment}
                                                    </>
                                                </Tooltip>
                                            </TableCell>
                                        ) : (
                                            <TableCell
                                                className={`${userId !== row.assigned_to_id &&
                                                row.status !== "PENDING REVIEW"
                                                    ? ''
                                                    : classes.overflow
                                                } ${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                }`}
                                            >
                                                <Tooltip title={row?.assignment?.length === 0 ? 'None' : row.assignment}>
                                                    <>
                                                    {(row?.screen_recording?.length ) ?
                                                        (
                                                            <Tooltip title={'A level in this SIM allows screen share recordings'}
                                                                      placement="top"
                                                            >
                                                                <div style={{
                                                                    marginRight: '1em',
                                                                    verticalAlign: 'middle',
                                                                    display: 'inline-block',
                                                                }}>
                                                                    <ScreenShareIcon fontSize="small"/>
                                                                </div>
                                                            </Tooltip>
                                                        ) : (<></>)
                                                    }
                                                    {row?.screen_recording_urls?.length ? (
                                                        <Tooltip
                                                            title={`User has ${row.screen_recording_urls.length} screen recordings in one or more levels of this SIM`}
                                                            placement="top"
                                                        >
                                                            <div style={{
                                                                marginRight: '1em',
                                                                verticalAlign: 'middle',
                                                                display: 'inline-block',
                                                            }}>
                                                                <VideocamIcon fontSize="small" />
                                                            </div>
                                                        </Tooltip>
                                                    ) : (<></>)}

                                                    {row?.assignment?.length > 50
                                                        ? `${row.assignment.slice(0, 50)}  ...`
                                                        : row.assignment}
                                                    </>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                        {/* <TableCell>{row.limit}</TableCell> */}
                                        {value === "allAssignment" ? (
                                            <TableCell
                                                onClick={() => handleClick(row)}
                                                // className={
                                                //   userId !== row.assigned_to_id &&
                                                //     row.status !== "PENDING REVIEW"
                                                //     ? classes.disabled
                                                //     : ""
                                                // }
                                            >
                                                {moment(new Date(row.assigned_on)).format(
                                                    "MMM DD YYYY"
                                                )}
                                            </TableCell>
                                        ) : (
                                            <TableCell
                                                className={`${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                }`}
                                            >
                                                {moment(new Date(row.assigned_on)).format(
                                                    "MMM DD YYYY"
                                                )}
                                            </TableCell>
                                        )}
                                        {value === "allAssignment" ? (
                                            <TableCell
                                                onClick={() => handleClick(row)}
                                                // className={
                                                //   userId !== row.assigned_to_id &&
                                                //     row.status !== "PENDING REVIEW"
                                                //     ? classes.disabled
                                                //     : ""
                                                // }
                                            >
                                                {row.assigned_by}
                                            </TableCell>
                                        ) : (
                                            <TableCell
                                                className={`${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                }`}
                                            >
                                                {row.assigned_by}
                                            </TableCell>
                                        )}
                                        {value === "allAssignment" || value === "myAssignment" || value === "completed" ? (
                                            <Tooltip
                                                title={row.notes?.length > 90 ? row.notes : ''}
                                                interactive={true}
                                            >
                                                <TableCell
                                                    className={`${userId !== row.assigned_to_id &&
                                                    row.status !== "PENDING REVIEW"
                                                        ? ''
                                                        : classes.adminNote
                                                    } ${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                    }`}
                                                >
                                                    {row?.notes?.length > 90
                                                        ? `${row.notes.slice(0, 90)}  ...`
                                                        : row.notes}
                                                </TableCell>
                                            </Tooltip>
                                        ) : null}
                                        {value === "completed" ? showLearnersMessage(row) : null}
                                        {value === "completed" ? (
                                            <TableCell
                                                className={`${userId !== row.assigned_to_id &&
                                                row.status !== "PENDING REVIEW"
                                                    ? classes.disabled
                                                    : ""
                                                } ${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                }`}
                                            >
                                                {moment(new Date(row.start_date)).format("MMM DD YYYY")}
                                            </TableCell>
                                        ) : null}
                                        {value === "completed" ? (
                                            <TableCell
                                                className={`${userId !== row.assigned_to_id &&
                                                row.status !== "PENDING REVIEW"
                                                    ? classes.disabled
                                                    : ""
                                                } ${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                }`}
                                            >
                                                {row.send_for_review_date
                                                    ? moment(new Date(row.send_for_review_date)).format(
                                                        "MMM DD YYYY"
                                                    )
                                                    : "---------"}
                                            </TableCell>
                                        ) : null}
                                        {value === "completed" ? (
                                            <TableCell
                                                className={`${userId !== row.assigned_to_id &&
                                                row.status !== "PENDING REVIEW"
                                                    ? classes.disabled
                                                    : ""
                                                } ${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                }`}
                                            >
                                                {row.date_of_review
                                                    ? moment(new Date(row.date_of_review)).format(
                                                        "MMM DD YYYY"
                                                    )
                                                    : "---------"}
                                            </TableCell>
                                        ) : null}
                                        {value === "readyForReview" ? null : value ===
                                        "allAssignment" ? (
                                            <TableCell
                                                padding="none"
                                                onClick={() => handleClick(row)}
                                                className={`${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                }`}
                                            >
                                                {row.status}
                                            </TableCell>
                                        ) : (
                                            <TableCell
                                                padding="none"
                                                className={`${userId !== row.assigned_to_id &&
                                                row.status !== "PENDING REVIEW"
                                                    ? classes.disabled
                                                    : ""
                                                } ${row.status === "NO PASS" ? classes.failedTableRow : ""
                                                }`}
                                            >
                                                {row.status}
                                            </TableCell>
                                        )}
                                        {value === "readyForReview" ? (
                                            <TableCell
                                                className={
                                                    userId !== row.assigned_to_id &&
                                                    row.status !== "PENDING REVIEW"
                                                        ? classes.disabled
                                                        : ""
                                                }
                                            >
                                                {moment(new Date(row.send_for_review_date)).format(
                                                    "MMM DD YYYY"
                                                )}
                                            </TableCell>
                                        ) : null}
                                        {showAction(row, index)}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div>
                        <div className={classes.paginationLabel}>
                            Showing
                            {' '}
                            {(page - 1) * 10 + 1}
                            {' '}
                            to
                            {' '}
                            {(page - 1) * 10
                                + (assignments && assignments.length ? assignments.length : 0)}
                            {' '}
                            of
                            {' '}
                            {total ? total : 0}
                            {' '}
                            entries
                        </div>
                        <Pagination
                            className={classes.pagination}
                            color="primary"
                            count={
                                total
                                    ? Math.ceil(total / 10)
                                    : 0
                            }
                            shape="rounded"
                            page={page}
                            onChange={handlePageChange}
                        />
                    </div>
                </Paper>
            )}
            {!assignment_loading && assignments.length === 0 ? (
                <div className={classes.noData}>
                    <img src={noAssignments} height={55} width={54} alt="noAssignments" />
                    <h3 className={classes.noAssign}>
                        {value === "allAssignment"
                            ? "There are no Assignments added yet!"
                            : "There are no Assignments."}
                    </h3>
                    {value === "allAssignment" &&
                    permissionCheck(
                        SimsPermissions.assignment.create.task,
                        SimsPermissions.assignment.create.type
                    ) ? (
                        <>
                            <p className={classes.noAssignPara}>
                                Let's get started with first Assignment
                            </p>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.addButton}
                                onClick={addAssignment}
                            >
                                <span className={classes.plus}>+</span> ADD ASSIGNMENT
                            </Button>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            ) : null}
        </div>
    );
};
AssignmentTable.propTypes = {
    assignments: PropTypes.array,
    total: PropTypes.number,
    sortProperties: PropTypes.func,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    value: PropTypes.string,
    handleShowReviewerButtons: PropTypes.func,
    saveAssignmentNotes: PropTypes.func,
    deleteAssignment: PropTypes.func,
    refreshCount: PropTypes.func,
    assignment_loading: PropTypes.bool,
    handlePageChange: PropTypes.func,
    page: PropTypes.number,
    saveCurrentSimProductType: PropTypes.func
};

const mapDispatchToProps = {
    handleShowReviewerButtons,
    saveAssignmentNotes,
    deleteAssignment,
    saveCurrentSimProductType
};

export default connect(null, mapDispatchToProps)(AssignmentTable);
