import React, { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import {
    Button,
    Dialog,
    TextField,
    DialogContent,
    Typography,
    DialogTitle,
    Checkbox,
    Tooltip,
    Divider,
    Modal,
} from '@material-ui/core';
import HelpIcon from "@material-ui/icons/Help";
import {
    useStyle,
    getModalStyle,
} from './CreateLevels';
import {
    setPassFailCriteriea,
    resetPassFail,
    resetPassFailModal,
} from "../../../redux/sims/actions";
import { removeSimPassFail } from '../../../services/result.service';

const PassFailCriteria = ({
    handlePassDialog,
    passDialog,
    passFailCriteriaRes,
    data,
    currentIndex,
    setPassFailCriteriea,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
    resetPassFail,
    openPassFailModal,
    resetPassFailModal,
}) => {
    const [passFailBool, setPassFailBool] = React.useState(false);
    const [passFailCriteriaStateRes, setPassFailCriteriaStateRes] = useState({});
    const [openModal, setOpenModal] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalBody, setModalBody] = React.useState("");
    const [showModalButton, setShowModalButton] = React.useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyle();
    const keywordInputRef = useRef(null);
    const typingInputRef = useRef(null);
    const dataSelInputRef = useRef(null);
    const overAllInputRef = useRef(null);
    const skipLevelRef = useRef(null);
    const [disableKeywordAccuracy, setDisableKeywordAccuracy] = React.useState(false);
    const [disableTypingAccuracy, setDisableTypingAccuracy] = React.useState(false);
    const [disableDataSelectionAccuracy, setDisableDataSelectionAccuracy] = React.useState(false);
    const [disableOverallAccuracy, setDisableOverallAccuracy] = React.useState(false);
    const [unsetPassFailCriteria, setUnsetPassFailCriteria] = React.useState(false);

    useEffect(() => {
        if (
            passFailCriteriaRes?.sim_level_id?.toString() === data.levels[currentIndex]?._id?.toString()
        ) {
            setPassFailCriteriaStateRes(passFailCriteriaRes);
            setPassFailBool(
                passFailCriteriaRes.allow_skip_levels
                    ? passFailCriteriaRes.allow_skip_levels
                    : false
            );
            setDisableKeywordAccuracy(
                passFailCriteriaRes.disable_threshold_keyword_accuracy
                    ? passFailCriteriaRes.disable_threshold_keyword_accuracy
                    : false
            );
            setDisableTypingAccuracy(
                passFailCriteriaRes.disable_threshold_typing_accuracy
                    ? passFailCriteriaRes.disable_threshold_typing_accuracy
                    : false
            );
            setDisableDataSelectionAccuracy(
                passFailCriteriaRes.disable_threshold_data_selection_accuracy
                    ? passFailCriteriaRes.disable_threshold_data_selection_accuracy
                    : false
            );
            setDisableOverallAccuracy(
                passFailCriteriaRes.disable_threshold_overall_accuracy
                    ? passFailCriteriaRes.disable_threshold_overall_accuracy
                    : false
            );
        } else {
            setPassFailCriteriaStateRes({});
            setPassFailBool(false);
            setDisableKeywordAccuracy(false);
            setDisableTypingAccuracy(false);
            setDisableDataSelectionAccuracy(false);
            setDisableOverallAccuracy(false);
        }
    }, [
        passFailCriteriaRes,
        setPassFailCriteriaStateRes,
        setPassFailBool,
        currentIndex,
        data
    ]);

    useEffect(() => {
        if (openPassFailModal) {
            // show successfully message
            setAlertType("success");
            setAlertMessage("Criteria is set successfully for this level!!!");
            setOpenAlert(true);
            // After 2 sec alert message hide
            setTimeout(() => {
                setAlertType("");
                setAlertMessage("");
                setOpenAlert(false);
                resetPassFailModal();
            }, 2000);
        }
    },[openPassFailModal, setAlertType, setAlertMessage, setOpenAlert, resetPassFailModal]);

    const handleModalClose = () => {
        setOpenModal(false);
        handlePassDialog("close");
    };

    const closeWithoutChanging = () => {
        setOpenModal(false);
        handlePassDialog("close");
        setUnsetPassFailCriteria(false);
        if (
            passFailCriteriaRes?.sim_level_id?.toString() ===
            data.levels[currentIndex]?._id?.toString()
        ) {
            setPassFailCriteriaStateRes(passFailCriteriaRes);
            setPassFailBool(
                passFailCriteriaRes.allow_skip_levels
                    ? passFailCriteriaRes.allow_skip_levels
                    : false
            );
            setDisableKeywordAccuracy(
                passFailCriteriaRes.disable_threshold_keyword_accuracy
                    ? passFailCriteriaRes.disable_threshold_keyword_accuracy
                    : false
            );
            setDisableTypingAccuracy(
                passFailCriteriaRes.disable_threshold_typing_accuracy
                    ? passFailCriteriaRes.disable_threshold_typing_accuracy
                    : false
            );
            setDisableDataSelectionAccuracy(
                passFailCriteriaRes.disable_threshold_data_selection_accuracy
                    ? passFailCriteriaRes.disable_threshold_data_selection_accuracy
                    : false
            );
            setDisableOverallAccuracy(
                passFailCriteriaRes.disable_threshold_overall_accuracy
                    ? passFailCriteriaRes.disable_threshold_overall_accuracy
                    : false
            );
        } else {
            setPassFailCriteriaStateRes({});
            setPassFailBool(false);
            setDisableKeywordAccuracy(false);
            setDisableTypingAccuracy(false);
            setDisableDataSelectionAccuracy(false);
            setDisableOverallAccuracy(false);
        }
    };

    const submitPassFailCriteria = () => {
        // Create criteria params
        const criteriaParams = {
            threshold_keyword_accuracy:
                keywordInputRef.current.value !== ""
                    ? Number(keywordInputRef.current.value)
                    : undefined,
            threshold_typing_accuracy:
                typingInputRef.current.value !== ""
                    ? Number(typingInputRef.current.value)
                    : undefined,
            threshold_data_selection_accuracy:
                dataSelInputRef.current.value !== ""
                    ? Number(dataSelInputRef.current.value)
                    : undefined,
            threshold_overall_accuracy:
                overAllInputRef.current.value !== ""
                    ? Number(overAllInputRef.current.value)
                    : undefined,
            allow_skip_levels: skipLevelRef.current.checked,
            status: "set",
            sim_level_id: data.levels[currentIndex]._id,
            sim_id: data.id,
            disable_threshold_keyword_accuracy: disableKeywordAccuracy,
            disable_threshold_typing_accuracy: disableTypingAccuracy,
            disable_threshold_data_selection_accuracy: disableDataSelectionAccuracy,
            disable_threshold_overall_accuracy: disableOverallAccuracy,
        };
        // when pass fail set for first time
        if (!data.is_pass_fail_criteria_set) {
            // dispatch setPassFailCriteriea action
            setPassFailCriteriea(criteriaParams);

            handlePassDialog("close");
        } else {
            const passFailLevel = data.levels.findIndex(lvl => lvl._id === data.pass_fail_id.sim_level_id);
            setModalTitle("Pass / Fail - Warning");
            setModalBody(
                `Pass / Fail criteria is already set for Level - ${passFailLevel + 1} of this sim, Are you sure you would like to make this change?`
            );
            setShowModalButton(true);
            setOpenModal(true);
        }
    };

    const anywaySubmitPassFail = () => {
        const criteriaParams = {
            threshold_keyword_accuracy:
                keywordInputRef.current.value !== ""
                    ? Number(keywordInputRef.current.value)
                    : undefined,
            threshold_typing_accuracy:
                typingInputRef.current.value !== ""
                    ? Number(typingInputRef.current.value)
                    : undefined,
            threshold_data_selection_accuracy:
                dataSelInputRef.current.value !== ""
                    ? Number(dataSelInputRef.current.value)
                    : undefined,
            threshold_overall_accuracy:
                overAllInputRef.current.value !== ""
                    ? Number(overAllInputRef.current.value)
                    : undefined,
            allow_skip_levels: skipLevelRef.current.checked,

            status: "set",
            sim_level_id: data.levels[currentIndex]._id,
            sim_id: data.id,
            disable_threshold_keyword_accuracy: disableKeywordAccuracy,
            disable_threshold_typing_accuracy: disableTypingAccuracy,
            disable_threshold_data_selection_accuracy: disableDataSelectionAccuracy,
            disable_threshold_overall_accuracy: disableOverallAccuracy,
        };
        // save _id to criteriaParams, if criteria setting for same level
        if (passFailCriteriaRes._id) {
            criteriaParams._id =
                passFailCriteriaRes?.sim_level_id?.toString() ===
                data.levels[currentIndex]?._id?.toString()
                    ? passFailCriteriaRes._id
                    : undefined;
        }
        setPassFailCriteriea(criteriaParams);
        setOpenModal(false);
        setModalTitle("");
        setModalBody("");
        setShowModalButton(false);
        handlePassDialog("close");
    };

    const unsetPassFail = () => {
        setModalTitle("Confirm");
        setModalBody(
            `Are you sure want to unset Pass/Fail criteria for this sim`
        );
        setShowModalButton(true);
        setUnsetPassFailCriteria(true)
        setOpenModal(true);
    };

    const removePassFail = async() => {
        await removeSimPassFail(data.levels[currentIndex]._id, data.id).then((res) =>{
            if(res.data.success) {
                setPassFailCriteriaStateRes({});
                setPassFailBool(false);
                setDisableKeywordAccuracy(false);
                setDisableTypingAccuracy(false);
                setDisableDataSelectionAccuracy(false);
                setDisableOverallAccuracy(false);
            }
        });
        setUnsetPassFailCriteria(false);
        setOpenModal(false);
        handlePassDialog("close");
        resetPassFail();
    }

    return (
        <>
            {/*<Button
                variant="outlined"
                className={classes.generateSimBtn}
                onClick={() => handlePassDialog('open')}
            >
                SET PASS/FAIL FOR THE SIM
            </Button>*/}
            <Dialog
                open={passDialog}
                onClose={handlePassDialog}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    <span style={{ margin: '0px' }}>Set Pass Criteria For Level {currentIndex + 1 } </span>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        <strong>
                            Set Pass Criteria (Select NA if criteria not applicable)
                        </strong>
                    </Typography>
                    <div className={classes.passFailDiv}>
                        Threshold Keyword Accuracy
                        {' '}
                        <TextField
                            disabled={disableKeywordAccuracy}
                            type="number"
                            inputRef={keywordInputRef}
                            defaultValue={passFailCriteriaStateRes.threshold_keyword_accuracy}
                            variant="outlined"
                            size="small"
                            className={classes.textfield}
                        />
                        {' '}
                        %
                        <Checkbox
                            checked={disableKeywordAccuracy}
                            onChange={(event) => setDisableKeywordAccuracy(event.target.checked)}
                            name="keyword"
                            className={classes.checkbox}
                        />
                        {' '}
                        NA
                    </div>
                    <div className={classes.passFailDiv}>
                        Threshold Typing Accuracy
                        {' '}
                        <TextField
                            disabled={disableTypingAccuracy}
                            inputRef={typingInputRef}
                            type="number"
                            defaultValue={passFailCriteriaStateRes.threshold_typing_accuracy}
                            variant="outlined"
                            size="small"
                            className={classes.textfield}
                        />
                        {' '}
                        <Checkbox
                            checked={disableTypingAccuracy}
                            onChange={(event) => setDisableTypingAccuracy(event.target.checked)}
                            name="typing"
                            className={classes.checkbox}
                        />
                        {' '}
                        NA
                    </div>
                    <div className={classes.passFailDiv}>
                        Threshold Data Selection Accuracy
                        {' '}
                        <TextField
                            disabled={disableDataSelectionAccuracy}
                            inputRef={dataSelInputRef}
                            defaultValue={passFailCriteriaStateRes.threshold_data_selection_accuracy}
                            type="number"
                            variant="outlined"
                            size="small"
                            className={classes.textfield}
                        />
                        {' '}
                        <Checkbox
                            checked={disableDataSelectionAccuracy}
                            onChange={(event) => setDisableDataSelectionAccuracy(event.target.checked)}
                            name="data"
                            className={classes.checkbox}
                        />
                        {' '}
                        NA
                    </div>
                    <div className={classes.passFailDiv}>
                        Threshold OverAll Accuracy
                        {' '}
                        <TextField
                            disabled={disableOverallAccuracy}
                            inputRef={overAllInputRef}
                            defaultValue={passFailCriteriaStateRes.threshold_overall_accuracy}
                            type="number"
                            variant="outlined"
                            size="small"
                            className={classes.textfield}
                        />
                        {' '}
                        %
                        <Checkbox
                            checked={disableOverallAccuracy}
                            onChange={(event) => setDisableOverallAccuracy(event.target.checked)}
                            name="overall"
                            className={classes.checkbox}
                        />
                        {' '}
                        NA
                    </div>
                    <div className={classes.passFailDiv}>
                        Allow Learner to skip levels
                        {' '}
                        <Checkbox
                            inputRef={skipLevelRef}
                            onChange={() => setPassFailBool(skipLevelRef.current.checked)}
                            checked={passFailBool}
                        />
                        {' '}
                        <Tooltip title="If this box is checked, Learners are allowed to skip levels and go directly to the You Try w/ No Help level and send for review">
                            <HelpIcon style={{ marginBottom: '-8px' }} />
                        </Tooltip>
                    </div>
                    <Divider />
                    <Button
                        onClick={submitPassFailCriteria}
                        color="primary"
                        variant="contained"
                        style={{
                            float: 'right',
                            margin: '0.5rem 1rem 0.5rem 0rem',
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            float: 'right',
                            margin: '0.5rem 1rem 0.5rem 0rem',
                        }}
                        onClick={() => closeWithoutChanging()}
                    >
                        Cancel
                    </Button>
                    {
                        Object.keys(passFailCriteriaStateRes).length > 0 ?
                            <Button
                                variant="contained"
                                style={{
                                    float: 'right',
                                    margin: '0.5rem 1rem 0.5rem 0rem',
                                }}
                                onClick={() => unsetPassFail()}
                            >
                                Unset Pass Fail
                            </Button>
                            : null
                    }

                </DialogContent>

            </Dialog>
            <Modal
                open={openModal}
                onClose={handleModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.modalPaper}>
                    <h2>{modalTitle}</h2>
                    <p>{modalBody}</p>
                    {showModalButton ? (
                        <>
                            {unsetPassFailCriteria ?
                                <Button
                                    onClick={removePassFail}
                                    color="primary"
                                    variant="contained"
                                    style={{ float: "right", margin: "0.5rem 1rem 0.5rem 0rem" }}
                                >
                                    Yes
                                </Button> :
                                <Button
                                    onClick={() => anywaySubmitPassFail()}
                                    variant="contained"
                                    style={{ float: "right", margin: "0.5rem 1rem 0.5rem 0rem" }}
                                >
                                    Continue
                                </Button>
                            }
                            <Button
                                onClick={() => closeWithoutChanging()}
                                variant="contained"
                                style={{ float: "right", margin: "0.5rem 1rem 0.5rem 0rem" }}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : null}
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({
    passFailCriteriaRes: state.sim.passFailCriteriaRes,
    openPassFailModal:  state.sim.openPassFailModal,
});

const mapDispatchToProps = {
    setPassFailCriteriea,
    resetPassFail,
    resetPassFailModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PassFailCriteria);
  