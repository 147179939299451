import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import Conversation from "./Conversation";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SimsPermissions from '../../permissions/sims';
import permissionCheck from "../../utils/permissionCheck";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import {DragDropArea} from "../common/DragDropArea.jsx";
import {
    addHelpdeskTicketAttachment,
    deleteHelpdeskTicketAttachment,
    getPresignedUrl,
    updateHelpdeskTicket,
    uploadToPresignedUrl
} from "../../services/result.service";
import useAlert from "../common/UseAlert";
import {formatSizeInBytes} from "../../utils/i18n";

const TicketDetails = ({
    ticket,
    openDetails,
    onClose,
    onTicketStatusChange,
    onReply,
    onLinkClick,
    canCloseTickets,
    canUpdateTickets,
}) => {
    const {setAlert} = useAlert();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTicket, setDialogTicket] = useState({});
    const [newEntryText, setNewEntryText] = useState('');
    const [canReply, setCanReply] = useState(false);
    const [canEditSims, setCanEditSims] = useState(false);
    const [fileAttachments, setFileAttachments] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [ticketModified, setTicketModified] = useState(false);
    const [ticketIsOpen, setTicketIsOpen] = useState(false);
    const [resetFiles, setResetFiles] = useState(false);

    useEffect(
        () => {
            setOpenDialog(openDetails);
        },
        [openDetails]
    );

    useEffect(
        () => {
            setDialogTicket(ticket);
            if (ticket) {
                const canDo = permissionCheck(
                    SimsPermissions.sim_list.update.task,
                    SimsPermissions.sim_list.update.type
                );
                setCanEditSims(canDo);
                setTicketIsOpen(['PENDING', 'IN PROGRESS'].indexOf(ticket.status) > -1);
            }
        },
        [ticket]
    );

    useEffect(
         () => {
             const updateAttachments = async () => {
                 let newAttachments = attachments.slice(0);
                 let newFiles = fileAttachments.slice(0);

                 if (!dialogTicket || !fileAttachments || !fileAttachments.length) {
                     return;
                 }

                 let anyProcessed = false;
                 for await (const [fileIndex, attachment] of fileAttachments.entries()) {
                     let processed = newAttachments.find((item) => {
                         return (item.status === 'uploaded') &&
                             (item.progress === 100) &&
                             ((item.file.name === attachment.file.name)||(item.file.name === attachment.name));
                     }) || attachment.url;

                     if (!processed) {
                         anyProcessed = true;
                         let signedUrls =  await getPresignedUrl('attachment', attachment.file.name);
                         if (signedUrls && signedUrls.success) {
                             attachment.status = 'uploading';
                             let uploaded = await uploadToPresignedUrl(signedUrls.presignedUrl, attachment.file);
                             if (uploaded && uploaded.success) {
                                 attachment.status = 'uploaded';
                                 attachment.progress = 100;
                                 let newAttachment = {
                                     attachment_id: crypto.randomUUID(),
                                     status: 'uploaded',
                                     progress: 100,
                                     file: {
                                         name: attachment.file.name,
                                         type: attachment.file.type,
                                         size: attachment.file.size,
                                     },
                                     url: signedUrls.downloadUrl,
                                 };
                                 let result = await addHelpdeskTicketAttachment(
                                     dialogTicket._id,
                                     {
                                         attachment_id: newAttachment.attachment_id,
                                         file: newAttachment.file,
                                         url: newAttachment.url,
                                     }
                                );
                                 if (result.success) {
                                     newAttachments.push(newAttachment);
                                     newFiles = newFiles.filter((_, newIndex) => newIndex !== fileIndex);
                                     setAlert('The attachment was uploaded successfully.', 'success');
                                 } else {
                                     setAlert('The attachment could not be uploaded.', 'error');
                                 }
                             } else {
                                 // TODO - Report failure to upload attachment
                                 attachment.status = 'error';
                                 setAlert('The attachment could not be uploaded.', 'error');
                             }
                         }
                     }
                 }

                 if (anyProcessed) {
                     setAttachments(newAttachments);
                     setResetFiles(true);
                     setFileAttachments(newFiles);
                     dialogTicket.attachments = [...dialogTicket.attachments, ...newAttachments];
                     setTicketModified(true);
                     setResetFiles(false);
                     console.log(attachments);
                 }
             }

             updateAttachments();

        },
        [fileAttachments]
    );

    const handleCloseDialog = async (evt) => {
        setOpenDialog(false);
        onClose && onClose(evt);
    }

    const handleReject = (evt) => {
        onTicketStatusChange && onTicketStatusChange('REJECTED');
    }

    const handleResolve = (evt) => {
        onTicketStatusChange && onTicketStatusChange('RESOLVED');
    }

    const handleTextChange = (evt) => {
        setNewEntryText(evt.target.value);
        setCanReply(true);
    }

    const handleSendReply = async (evt) => {
        onReply && onReply(evt, newEntryText);
        setNewEntryText('');
        setCanReply(false);
    }

    const handleLinkClick = async (evt, simId) => {
        onLinkClick && onLinkClick(evt, simId);
    }

    const handleAttachments = async (fileAttachments) => {
        setFileAttachments(fileAttachments);
    }

    const handleDeleteAttachment = async (attachmentIndex) => {
        let result = deleteHelpdeskTicketAttachment(dialogTicket.ticket_id, attachmentIndex);
        if (result && result.success) {
            let newAttachments = dialogTicket.attachments.filter((attachment, idx) => {
                return idx !== attachmentIndex;
            });
            dialogTicket.attachments = newAttachments.slice(0);
            setTicketModified(true);
        }
    }

    console.log('DialogTicket = %o', dialogTicket);

    return (
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            maxWidth={'lg'}
            fullWidth={true}
        >
            <DialogTitle id="scroll-dialog-title">Helpdesk Ticket Details [{dialogTicket?.status}]</DialogTitle>
            <DialogContent dividers={true} style={{maxHeight: '58vh', overflowY: 'hidden'}}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        {dialogTicket?.assignment && (
                            <Grid container spacing={2}>
                                <Grid item xs={6}><strong>Assignment</strong></Grid>
                                <Grid item xs={6}><strong>{dialogTicket?.assignment?.status}</strong></Grid>
                            </Grid>
                        )}
                        {dialogTicket?.sim ? (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>SIM</Grid>
                            <Grid item xs={6}>
                                {canEditSims ? (
                                    <Link to={`/sims/createsim?simId=${dialogTicket?.sim_id ?? ''}`}
                                          onClick={(evt) => handleLinkClick(evt, dialogTicket?.sim_id ?? '')}>
                                        {dialogTicket?.sim?.title}
                                    </Link>
                                ) : (
                                    <span>{dialogTicket?.sim?.title}</span>
                                )}
                            </Grid>
                        </Grid>
                        ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>Page</Grid>
                                <Grid item xs={6}>
                                    <Link to={`${dialogTicket?.page}`}>
                                        {dialogTicket?.page}
                                    </Link>
                            </Grid>
                        </Grid>
                        )}
                        {dialogTicket?.sim && (
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>Level</Grid>
                                    <Grid item xs={6}>{dialogTicket?.level?.name}</Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>Script</Grid>
                                    <Grid item xs={6}>{dialogTicket?.script?.order}</Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>Script Voice</Grid>
                                    <Grid item xs={6}>{dialogTicket?.script?.voice ?? '- Agent -'}</Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>Script Text</Grid>
                                    <Grid item xs={6}>{dialogTicket?.script?.script}</Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>Script Audio</Grid>
                                    <Grid item xs={6}>
                                        {dialogTicket?.script?.audio_url ? (
                                            <a href={`https:////${dialogTicket?.script?.audio_url?.replace("https://", "")}`}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                            >Open Audio</a>
                                        ) : (
                                            dialogTicket?.script?.agent_recorded_url ? (
                                                    <a href={`https:////${dialogTicket?.script?.agent_recorded_url?.replace("https://", "")}`}
                                                       target="_blank"
                                                       rel="noopener noreferrer"
                                                    >Open Audio</a>
                                                ) : (
                                                    <>N/A</>
                                                )

                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid container spacing={2} visible={dialogTicket?.attachments?.length}>
                            <Grid item xs={12}>Attachments</Grid>
                            <Grid item xs={12}>
                                <List style={{maxHeight: '12em', minWidth: '32ch', overflow: 'auto'}}>
                                    {dialogTicket?.attachments?.map((attachment, attachmentIndex) => (
                                        <ListItem>
                                            <ListItemIcon style={{ margin: '0 0.5em' }}>
                                                {attachment?.file?.type.indexOf('image') !== -1 ? (
                                                    <img width={64} src={attachment?.url} alt='Preview'/>
                                                ) : (
                                                    <></>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText>
                                                <p style={{lineHeight: '1.1em', margin: '0 0.25em' }}>
                                                    <a href={attachment?.url ?? '#'}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >{attachment.file?.name ?? ''}</a>
                                                </p>
                                                <p style={{lineHeight: '1.1em', margin: '0 0.25em' }}>{attachment.file?.type ?? ''}</p>
                                                <p style={{lineHeight: '1.1em', margin: '0 0.25em' }}>{formatSizeInBytes(attachment.file?.size ?? 0)}</p>
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => handleDeleteAttachment(attachmentIndex)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                        {ticketIsOpen ? (
                            <Grid item xs={12}>
                                <DragDropArea attachments={attachments} resetFiles={resetFiles} onFilesSelected={handleAttachments}/>
                            </Grid>
                        ) : (<></>)}
                    </Grid>
                    <Grid item xs={8} style={{paddingBottom: '0'}}>
                        <Grid container spacing={2} columns={1} direction={'column'} style={{height: '100%', flexWrap: 'nowrap'}}>
                            <Grid item xs={12} style={{flex: '1 1 80%'}}>
                                <Conversation helpdeskTicket={dialogTicket} expanded={true} style={{maxHeight: '50vh'}}/>
                            </Grid>
                            <Grid item xs={12} style={{flex: "1 1 0", paddingBottom: 0}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={11}>
                                        <TextField fullWidth
                                                   multiline={true}
                                                   onChange={handleTextChange}
                                                   value={newEntryText}
                                        ></TextField>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button onClick={handleSendReply}
                                                style={{padding: '0.25em', color: 'rgb(0, 128, 0)', cursor: 'pointer', pointerEvents: 'all'}}
                                                color="primary"
                                                disabled={!canReply || !canUpdateTickets}
                                        >
                                            <SendIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {(canCloseTickets && ticketIsOpen) && (
                    <ButtonGroup style={{marginInline: '0 auto'}}>
                        <Button variant="outlined"
                                style={{backgroundColor: 'rgba(255, 200, 200, 0.8)', color: 'rgb(128, 0, 0)'}}
                                startIcon={<ThumbDownIcon/>}
                                onClick={handleReject}
                        >
                            Reject
                        </Button>
                        <Button variant="outlined"
                                style={{backgroundColor: 'rgba(200, 255, 200, 0.8)', color: 'rgb(0, 128, 0)'}}
                                startIcon={<ThumbUpIcon/>}
                                onClick={handleResolve}
                        >
                            Resolve
                        </Button>
                    </ButtonGroup>
                )}
                <ButtonGroup style={{marginInline: 'auto 0'}}>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    );
}

export default TicketDetails;
