import moment from "moment";

export const  dateFormat = (dateValue) => {
    return moment(dateValue).format('MM-DD-YYYY hh:mm a');
}

export const dayTime = (date = null) => {
    let timeStr = '';
    // To find the relative time for greeting message in header
    const currentTime = date ?? new Date();
    if (currentTime.getHours() < 12) {
        timeStr = 'Morning';
    } else if (currentTime.getHours() < 16) {
        timeStr = 'Afternoon';
    } else if (currentTime.getHours() < 20) {
        timeStr = 'Evening';
    } else if (currentTime.getHours() < 23) {
        timeStr = 'Night';
    }

    return timeStr;
}

export const formatSizeInBytes = (value) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let bytes = value,
        unitIndex = 0;

    while (bytes && (bytes > 1024)) {
        bytes /= 1024;
        unitIndex++;
    }/*
    let bytes = value,
        sizeUnit = 'B';
    if (bytes > 1024) {
        bytes = bytes / 1024;
        sizeUnit = 'KB';
    }
    if (bytes > 1024) {
        bytes = bytes / 1024;
        sizeUnit = 'MB';
    }
    if (bytes > 1024) {
        bytes = bytes / 1024;
        sizeUnit = 'GB';
    }
    if (bytes > 1024) {
        bytes = bytes / 1024;
        sizeUnit = 'TB';
    }*/

    return bytes.toLocaleString(undefined, {maximumSignificantDigits: 2})+units[unitIndex];//sizeUnit;
}