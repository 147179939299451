import { makeStyles } from '@material-ui/core/styles';
import WhiteDottedBackground from '../../../assets/images/takeSim/whitedotted.png';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles((theme) => ({
    videoDialog: {
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        margin: '0',
        padding: '0',

        '& .muiBackdrop-root': {
            backgroundColor: 'rgba(255, 200, 200, 0.4)',
        },
        '& .MuiDialog-container.MuiDialog-scrollPaper': {
            left: '0 !important',
            top: '0 !important',
            width: '100% !important',
            height: '100% !important',
            maxWidth: '100% !important',
            maxHeight: '100% !important',
        },
        '& .MuiDialog-paper': {
            maxWidth: 'unset !important',
            maxHeight: 'unset !important',
            width: '100% !important',
            height: '100% !important',
            padding: '0 !important',
            margin: '0 !important',
        },
    },
    previewDialogTitle: {
        color: 'white',
        background: '#264173',
    },
    closeButton: {
        color: 'white',
        float: 'right',
        marginTop: -6,
    },
    previewDialog: {
        textAlign: 'center',
        margin: 0,
        position: 'relative',
        width: '100%',
        height: 'calc(100% - 4.25em)',
        overflow: 'auto',
        cursor: 'grab',

        '& .react-transform-wrapper': {
            height: '100%',
            width: '100%',

            '& .react-transform-component': {
                height: '100%',
                width: '100%',
            },
        },
    },
    videoModal: {
        border: '2px solid black',
        position: 'relative'
    },
    success: {
        fontSize: '1rem',
        color: '#2CB174',
        marginRight: '0.25em',
    },
    videoPlayer: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        padding: '0',
    },
    zoomBar: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '1em',
        left: '1em',
        zIndex: '9999',
        isolation: 'isolate',
        backgroundColor: '#DEDEDE',
        border: '1px solid #33333388',
        borderRadius: '8px',
        boxShadow: '2px 2px 4px 0 #88888888',
        opacity: '0.4',

        '&:hover': {
            opacity: '1',
        }
    },
    dialogFooter: {
        padding: '0em',
        textAlign: 'left',
        height: '4em',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '1rem',
        border: '2px solid red',
    },
}));

export default useStyles;