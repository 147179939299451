import {
    IconButton,
    Tooltip
} from "@material-ui/core";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {createHelpdeskTicket, getPresignedUrl, uploadToPresignedUrl} from "../../services/result.service";
import {useLocation} from "react-router-dom";
import helpdeskPermissions from "../../permissions/helpdesk";
import permissionCheck from "../../utils/permissionCheck";
import {HelpRequestDialog} from "./HelpRequestDialog";
import useAlert from "../common/UseAlert";

const useQuery = () => new URLSearchParams(useLocation().search);

const HelpRequestButton = ({
    simData,
    classes,
}) => {
    const location = useLocation();
    const query = useQuery();
    const [helpDialogOpen, setHelpDialogOpen] = React.useState(false);
    const [page, setPage] = React.useState('');
    const [helpText, setHelpText] = React.useState("");
    const [attachments, setAttachments] = useState([]);
    const [fileAttachments, setFileAttachments] = useState([]);
    const {setAlert} = useAlert();

    React.useEffect(
        () => {
            setPage(location.pathname);
        },
        [location]
    );

    React.useEffect(
        () => {
            const updateAttachments = async () => {
                let newAttachments = attachments.slice(0);
                let processed = false;

                for await (const [fileIndex, attachment] of fileAttachments.entries()) {
                    if (!attachment.url) {
                        let attachment_id = crypto.randomUUID();
                        let signedUrls =  await getPresignedUrl('attachment', attachment_id);
                        if (signedUrls && signedUrls.success) {
                            attachment.status = 'uploading';
                            let uploaded = false;
                            try {
                                uploaded = await uploadToPresignedUrl(signedUrls.presignedUrl, attachment.file);
                            } catch (err) {
                                setAlert('Could not upload attachment', 'error');
                            }
                            if (uploaded && uploaded.success) {
                                processed = true;
                                attachment.status = 'uploaded';
                                attachment.progress = 100;
                                newAttachments.push(
                                    {
                                        attachment_id: attachment_id,
                                        file: {
                                            name: attachment.file.name,
                                            type: attachment.file.type,
                                            size: attachment.file.size,
                                        },
                                        url: signedUrls.downloadUrl,
                                    }
                                );
                                fileAttachments[fileIndex].url = signedUrls.downloadUrl;
                                setAlert('Attachment uploaded successfully.', 'success');
                            } else {
                                // TODO - Report failure to upload attachment
                                attachment.status = 'error';
                                setAlert('Failed to upload attachment', 'error');
                            }
                        }
                    }
                }

                if (processed) {
                    setAttachments(newAttachments);
                }

                console.log('Set attachments to = %o', newAttachments);
            }

            updateAttachments();
        },
        [fileAttachments]
    );

    const handleClick = (evt) => {
        setHelpDialogOpen(true);
    }

    const handleAttachments = async (fileAttachments) => {
        setFileAttachments(fileAttachments);
    }

    const handleCloseDialog = async (evt) => {
        if (evt.currentTarget.id === 'sendButton') {
            debugger;
            let helpRequest = {
                sim_id: simData?.sim_id,
                level_id: simData?.level?._id ?? simData?.level,
                script: simData?.step,
                message: helpText,
                page: page,
                attachments: attachments,
            }
            if (query.get("assignment_id")) {
                helpRequest.assignment_id = query.get("assignment_id");
            }
            console.log('Help Request: %o', helpRequest);

            // Create the helpdesk ticket in the backend
            let result = await createHelpdeskTicket(helpRequest);

            if (result && result.success) {
                setAlert('The Helpdesk ticket was created successfully.', 'success');
            } else {
                setAlert('The Helpdesk ticket was NOT created.', 'error');
                console.error(result);
            }
        }
        setHelpDialogOpen(false);
        setHelpText('');
    }

    if (!permissionCheck(helpdeskPermissions.tickets.create.task, helpdeskPermissions.tickets.create.type)) {
        return (<></>);
    }

    return (
        <>
            <Tooltip title="Request Help">
                <span>
                    <IconButton onClick={handleClick} color="primary">
                      <LiveHelpIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <HelpRequestDialog dialogOpen={helpDialogOpen}
                               onCloseDialog={handleCloseDialog}
                               helpText={helpText}
                               setHelpText={setHelpText}
                               attachments={attachments}
                               setAttachments={setAttachments}
                               handleAttachments={handleAttachments}
                               classes={classes}
            />
        </>
    )
}

HelpRequestButton.propTypes = {
    simData: PropTypes.object,
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(HelpRequestButton);
