import {FETCH_HELPDESK_TICKETS} from "./helpdeskTypes";
import {FETCH_FAILURE} from "../sims/simTypes";
import axios from "axios";
import config from "../../config";
import {fetchRequest} from "../sims/actions";
import helpdeskPermissions from "../../permissions/helpdesk";

export const fetchFailure = (data) => ({
    type: FETCH_FAILURE,
    payload: data,
});

export const fetchHelpdeskTicket = (data) => ({
    type: FETCH_HELPDESK_TICKETS,
    payload: data,
});

export const fetchHelpdeskTickets = (
    page = 0, textSearch = null, orderBy = null, order = null, filter = null
) => async (dispatch) => {
    dispatch(fetchRequest());
    let task = helpdeskPermissions.tickets.view.task;
    let type = helpdeskPermissions.tickets.view.type;
    await axios.post(
        `${config.API_BASE_URL}/api/v1/help-desk/tickets/list?page=${page ?? 0}&orderBy=${orderBy ?? 'createdAt'}&order=${order ?? 'asc'}`,
        {
            page: page,
            textSearch: textSearch,
            orderBy: orderBy,
            order: order,
            filters: filter,
        },
        {
            headers: {
                Authorization: config.getToken(),
                task,
                type,
            }
        }
    ).then((res) => dispatch(fetchHelpdeskTicket(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};
