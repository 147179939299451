import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {DragDropArea} from "../common/DragDropArea.jsx";

export const HelpRequestDialog = ({
    dialogOpen,
    onCloseDialog,
    helpText,
    setHelpText,
    handleAttachments,
}) => {

    const handleCloseDialog = async (evt) => {
        onCloseDialog(evt);
        setHelpText('');
    }

    const handleTextChange = (evt) => {
        setHelpText(evt.target.value);
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            maxWidth={'md'}
            fullWidth={true}
        >
            <DialogTitle id="scroll-dialog-title">Request Help</DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <InputLabel htmlFor="ticketMessage">Message</InputLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField id="ticketMessage"
                                       fullWidth
                                       multiline={true}
                                       required={true}
                                       minRows={4}
                                       onChange={handleTextChange}
                                       value={helpText}
                                       variant="outlined"
                            ></TextField>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <InputLabel htmlFor="attachmentsList">Attachments</InputLabel>
                        </Grid>
                        <Grid item xs={10} style={{display: 'flex', flexDirection: 'row'}}>
                            <DragDropArea onFilesSelected={handleAttachments} fileUploadProgress={[]}/>
                        </Grid>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}
                        id='cancelButton'
                        color='secondary'
                        variant='contained'
                >
                    Cancel
                </Button>
                <Button onClick={handleCloseDialog}
                        id='sendButton'
                        color='primary'
                        variant='contained'
                >
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    )
}

HelpRequestDialog.propTypes = {
    dialogOpen: PropTypes.bool,
    onCloseDialog: PropTypes.func,
    helpText: PropTypes.string,
    setHelpText: PropTypes.func,
    attachments: PropTypes.array,
    handleAttachments: PropTypes.func,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(HelpRequestDialog);
