import {
    FETCH_REQUEST,
    FETCH_SUCCESS,
    FETCH_FAILURE,
    FETCH_HELPDESK_TICKETS,
} from './helpdeskTypes';

const initialState = {
    helpdeskTicketsLoading: false,
    helpdeskTickets: [],
};

const helpdeskReducer = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case FETCH_REQUEST:
            return {
                ...state,
                helpdeskTicketsLoading: true
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                helpdeskTicketsLoading: true,
                data: action.payload.data,
                helpdeskTickets: action.payload.data.helpdeskTickets,
                helpdeskStats: {
                    total: action.payload.data.total,
                    unassigned: action.payload.data.unassigned,
                    archived: action.payload.data.archived,
                    resolved: action.payload.data.resolved,
                    rejected: action.payload.data.rejected,
                    pending: action.payload.data.pending,
                    inProgress: action.payload.data.inProgress,
                }
            };
        case FETCH_FAILURE:
            const status = payload.message ? payload.message.split(' ') : [];
            if (status[status.length - 1] === '404') {
                localStorage.setItem('redirectUrl', '/dashboard');
                alert('The item you selected could not be found.');
                window.history.back();
                //window.location.href = window.location. '/dashboard';
                return {
                    ...state,
                    helpdeskTicketsLoading: true,
                    data: [],
                    helpdeskTickets: action.payload.data?.helpdeskTickets ?? [],
                    permissionDenied: true,
                };
            }
            if (status[status.length - 1] === '403') {
                localStorage.setItem('redirectUrl', '/permissiondenied');
                window.location.href = '/permissiondenied';
                return {
                    ...state,
                    helpdeskTicketsLoading: true,
                    data: [],
                    helpdeskTickets: action.payload.data?.helpdeskTickets ?? [],
                    permissionDenied: true,
                };
            }
            if (status[status.length - 1] === '401') {
                window.location.href = '/login';
                localStorage.clear('token');
                localStorage.clear('user');
                return {
                    ...state,
                    helpdeskTicketsLoading: true,
                    data: action.payload.data,
                    helpdeskTickets: action.payload.data?.helpdeskTickets ?? [],
                    status: action.payload,
                };
            }
            return {
                ...state,
                helpdeskTicketsLoading: true,
                data: action.payload.data,
                helpdeskTickets: action.payload.data.helpdeskTickets,
                status: status[status.length - 1],
            };
        case FETCH_HELPDESK_TICKETS:
            return {
                ...state,
                helpdeskTicketsLoading: false,
                data: action.payload.data,
                helpdeskTickets: action.payload.data?.helpdeskTickets ?? [],
                helpdeskStats: {
                    total: action.payload.data?.total ?? 0,
                    unassigned: action.payload.data?.unassigned ?? 0,
                    archived: action.payload.data?.archived ?? 0,
                    resolved: action.payload.data?.resolved ?? 0,
                    rejected: action.payload.data?.rejected ?? 0,
                    pending: action.payload.data?.pending ?? 0,
                    inProgress: action.payload.data?.inProgress ?? 0,
                }
            };
        default:
            return state;
    }
};

export default helpdeskReducer;
