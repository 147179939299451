import {
    FETCH_REQUEST,
    FETCH_SUCCESS,
    FETCH_FAILURE,
    FETCH_STATS,
    FETCH_CURRENT,
    FETCH_KEYWORD,
    FETCH_KBCA,
    INCREASE_NEWSIM_STEP,
    DECREASE_NEWSIM_STEP,
    ADD_AVATAR_DETAILS,
    ADD_BASIC_INFORMATION,
    INITIALIZE_NEWSIM,
    ADD_LEVELS,
    FETCH_CHARACTERS,
    ADD_KEYWORD,
    FETCH_LEVEL_CLIPS,
    FETCH_TAKESIM,
    FETCH_SCREENING_KEYWORD,
    FETCH_INSURANCES,
    FETCH_PHYSICIAN_MAP_FORM,
    SELECTED_KBCA,
    FETCH_DISPOSITIONS,
    ADD_DISPOSITIONS,
    FETCH_ARTICLES,
    SELECTED_ARTICLE,
    SAVE_ADDRESS,
    FETCH_OPPORTUNITY,
    SAVE_OPPORTUNITY,
    FETCH_OPPBYNAME,
    FETCH_USERDATA,
    FETCH_TOTAL_ASSIGNMENTS,
    FETCH_EMPLOYEE_LIST,
    FETCH_MEMBERS_LIST,
    FETCH_ASSIGNMENTS,
    FETCH_GROUPS_LIST,
    FETCH_SELECTION_DATA,
    ADD_ASSIGNMENT,
    CHANGE_PRODUCT_ACCESS,
    FETCH_PRODUCTS,
    FETCH_PERMISSIONS_LIST,
    FETCH_SIM_DETAILS,
    SET_EDIT_SIM_STEP,
    SAVE_ELAPSED_TIME,
    SAVE_WRAP_SECONDS,
    FETCH_USERS,
    ADD_NEW_LEVELS,
    GENERATE_SIM,
    ADD_CLIP_AUDIO,
    FETCH_SIM_LEVEL_USER_RESPONSE,
    FETCH_USER_FINAL_SCORE_RESPONSE,
    FETCH_SAVED_KEYWORDS,
    RESET,
    SAVE_CASE_VALUES,
    SAVE_USER,
    SET_PASS_FAIL_CRITERIEA,
    FETCH_AUTO_POPULATE,
    FETCH_GROUP_LIST,
    FETCH_USER_DATA,
    UPLOAD_LEVELS,
    NOTES_SAVE,
    FETCH_CURRENT_STATUS,
    DELETE_PREVIOUS_ASSIGNMENT_DETAILS,
    TOGGLE_RESULT_BUTTONS,
    SAVE_SIM_TITLE,
    SELECTED_SIM,
    CURRENT_AUDIO_PLAYING,
    AUDIO_PLAY,
    ADD_ASSESSOR_NOTES,
    SAVE_ASSESSOR_NOTES,
    SHOW_REVIEWER_BUTTON,
    FETCH_SCORES,
    ALL_LEVEL_AGENT_AUDIO_PROCESS,
    ADD_SCREEN_RECORDER_BLOB,
    SET_EDIT_SIM_DETAILS_STEP,
    RESET_STATS,
    UPDATE_CURRENT_CLIP,
    RESTART_LEVEL_IN_TAKE_SIM,
    ENABLE_VIDEO_RECORDING,
    RESET_VIDEO_STATS,
    VIDEO_UPLOAD_STATUS,
    FETCH_ASSIGNMENT_COUNTS,
    FETCH_TRASHED_SIMS,
    SET_VIDEO_BLOB,
    CLOSE_CAPTIVATE_WINDOW,
    RESET_PASS_FAIL,
    RESET_PASSFAIL_MODAL,
    SIM_PRODUCT_TYPE
} from './simTypes';

const initialState = {
    isLoading: false,
    permissionDenied: false,
    currentSim: {},
    simTitle: '',
    selectedSim: {},
    total: null,
    data: [],
    deletedSims: [],
    status: null,
    newAssignment: {
        tool: '',
        assignment: '',
        member: '',
        // limit: '',
        assignmentNote: '',
        isGroup: '',
    },
    currentUserData: {
        _id: '',
        first_name: '',
        last_name: '',
        nick_name: '',
        title: '',
        email_id: '',
        phone_number: '',
        profile_pic_url: '',
        status: '',
        sub_status: '',
        role: '',
        schedule: '',
        duration: '',
        employment: '',
        workplace: '',
        joined_company: '',
        probation: '',
        promoted: '',
        groups: [],
        password: '',
        outsourcer: '',
    },
    newSim: {
        id: '',
        activeStep: 0,
        title: '',
        description: '',
        brief_description: '',
        admin_note: '',
        product_id: '',
        tutorial_video_name: '',
        captivate_folder_name: '',
        captivate_demo_video: '',
        sim_type: 'training',
        call_type: 'Physician Referral',
        auto_populate: false,
        documents: [],
        real_time_feedback: false,
        natural_lenguage : false,
        draft: false,
        status: '',
        sheet_name: '',
        selectVoices: {
            Joanna: 'Joanna',
            Kendra: 'Kendra',
            Kimberly: 'Kimberly',
            Salli: 'Salli',
            Joey: 'Joey',
            Matthew: 'Matthew',
            Ruth: 'Ruth',
        },
        sim_characters: [
            {
                name: 'Jessie',
                voice: 'Kendra',
                type: 'customer',
                avatar: 'avatar-13',
                masked_type: '',
            },
            {
                name: 'You',
                voice: 'Joanna',
                type: 'agent',
                avatar: 'avatar-6',
                masked_type: '',
            },
            {
                name: 'KJ',
                voice: 'Joey',
                type: 'coach',
                avatar: 'avatar-3',
                masked_type: '',
            },
            {
                name: 'Jim',
                voice: 'Matthew',
                type: 'other',
                avatar: 'avatar-19',
                masked_type: '',
            },
        ],
        levels: [],
        dispositions: {
            callType: '--None--',
            primaryDisposition: '--None--',
            secondaryDisposition: '--None--',
            callStatus: '--None--',
            agentActionTakenSummary: '--None--',
            warmTransferredTo: '',
            languageLineUsed: '--None--',
            consumerReasonForCallingSummary: '',
            miscNotes: '',
        },
        addressDetails: {
            street: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            rangeinmiles: '',
            selectanInsurance: '',
        },
        fetchStatus: null,
    },
    createKeywords: [],
    autoPopulate: [],
    levelClips: {},
    levelClipsTemp: {},
    characters: [],
    screening: [],
    insurances: [],
    physicianMapForm: {},
    kbca: [],
    selectedKbca: [],
    dispositions: [],
    selectedArticle: '',
    articles: [],
    loadedOpportunities: [],
    savedOpportunity: {},
    opportunitiesByName: [],
    products: [],
    employeeList: [],
    membersList: [],
    groups: [],
    userResponse: {},
    userResult: {},
    caseValuesArray: {},
    resultScreenInputData: {},
    passFailCriteriaRes: {},
    notes: [],
    currentAssignmentStatus: {},
    showResultButtons: false,
    takeSimFromAssignmentSection: false,
    currentAudioPlaying: '',
    audioPlaying: '',
    assessorNotes: false,
  showReviewerButtons: true,
    assessor_notes: '',
    allAudioProcessed: false,
    screenRecorderBlob: '',
    stats: {},
    currentClip: {},
    isRestartLevel: false,
    sim_listing: false,
    employee_listing: false,
    assignment_loading: false,
    videoRecordingEnabled: false,
    dashboard_listing: false,
    videoUploadStatus: null,
    videoBlob: {},
    captivateWindowRef: {},
    assignmentDataCount: {
        allAssignmentCount: 0,
        myAssignmentCount: 0,
        completedAssignmentCount: 0,
        readyForReviewCount: 0,
    },
    openPassFailModal: false,
    elapsedTime: 0,
    totalSeconds: 0,
    wrapSeconds: 0,
    currentSimProductType: '',
};

const reducer = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case FETCH_REQUEST:
            return {
                ...state,
                isLoading: true,
                sim_listing: true,
                dashboard_listing: true,
                employee_listing: true,
                assignment_loading: true,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.data.sims,
                total: action.payload.data.total,
                status: action.payload.status,
                employeeList: [],
                assignments: { assignments: [] },
                sim_listing: false,
                result: [],
            };
        case FETCH_STATS:
            return {
                ...state,
                stats: action.payload.data,
                userResponse: {},
                userResult: {},
            };
        case FETCH_LEVEL_CLIPS:
            return {
                ...state,
                levelClips: action.payload.data,
                levelClipsTemp: action.payload.data,
            };
        case ADD_CLIP_AUDIO:
            return {
                ...state,
                levelClips: action.payload,
            };
        case FETCH_KBCA:
            return {
                ...state,
                kbca: action.payload.data,
            };
        case FETCH_FAILURE:
            const status = payload.message ? payload.message.split(' ') : [];
            if (status[status.length - 1] === '403') {
                localStorage.setItem('redirectUrl', '/permissiondenied');
                window.location.href = '/permissiondenied';
                return {
                    ...state,
                    isLoading: false,
                    sim_listing: false,
                    dashboard_listing: false,
                    employee_listing: false,
                    assignment_loading: false,
                    permissionDenied: true,
                };
            }
            if (status[status.length - 1] === '401') {
                window.location.href = '/login';
                localStorage.clear('token');
                localStorage.clear('user');
                return {
                    ...state,
                    isLoading: false,
                    isAuth: false,
                    sim_listing: false,
                    assignment_loading: false,
                    data: action.payload.data,
                    status: action.payload,
                };
            }
            return {
                ...state,
                isLoading: false,
                data: action.payload.data,
                sim_listing: false,
                assignment_loading: false,
                status: status[status.length - 1],
            };

        case FETCH_CURRENT:
            return {
                ...state,
                isLoading: false,
                currentSim: action.payload,
                status: action.payload.status,
            };
        case FETCH_CHARACTERS:
            return {
                ...state,
                isLoading: false,
                characters: action.payload.data,
                status: action.payload.status,
            };
        case FETCH_KEYWORD:
            return {
                ...state,
                isLoading: false,
                createKeywords: action.payload,
                status: action.payload.status,
            };
        case FETCH_SAVED_KEYWORDS:
            return {
                ...state,
                isLoading: false,
                createKeywords: action.payload,
                status: action.payload.status,
            };
        case INCREASE_NEWSIM_STEP:
            let newStep = state.newSim.activeStep + 1,
                updatedSim = {...state.newSim, activeStep: newStep};
            return {
                ...state,
                newSim: updatedSim,
            };
        case DECREASE_NEWSIM_STEP:
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    activeStep: state.newSim.activeStep - 1,
                },
            };
        case ADD_AVATAR_DETAILS:
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    ...action.payload,
                },
            };
        case ADD_LEVELS:
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    ...action.payload,
                },
            };
        case ADD_BASIC_INFORMATION:
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    ...action.payload,
                    id: action.result,
                },
            };

        case INITIALIZE_NEWSIM:
            return {
                ...state,
                newSim: {
                    ...initialState.newSim,
                },
                articles: [],
                data: [],
                currentSim: {},
            };
        case ADD_KEYWORD:
            return {
                ...state,
                createKeywords: action.payload,
            };
        case FETCH_TAKESIM:
            return {
                ...state,
                isLoading: false,
                createKeywords: action.payload,
                // status: action.payload.status,
            };
        case FETCH_SCREENING_KEYWORD:
            return {
                ...state,
                isLoading: false,
                screening: action.payload.data,
                status: action.payload.status,
            };
        case FETCH_INSURANCES:
            return {
                ...state,
                isLoading: false,
                insurances: action.payload.data,
                status: action.payload.status,
            };
        case FETCH_PHYSICIAN_MAP_FORM:
            return {
                ...state,
                isLoading: false,
                physicianMapForm: action.payload.data,
                status: action.payload.status,
            };
        case SELECTED_KBCA:
            return {
                ...state,
                selectedKbca: [action.payload, ...state.selectedKbca],
            };

        case FETCH_DISPOSITIONS:
            return {
                ...state,
                isLoading: false,
                dispositions: action.payload.data,
                status: action.payload.status,
            };
        case ADD_DISPOSITIONS:
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    ...action.payload,
                },
            };
        case FETCH_ARTICLES:
            return {
                ...state,
                isLoading: false,
                articles: action.payload.data,
                status: action.payload.status,
            };
        case SELECTED_ARTICLE:
            return {
                ...state,
                selectedArticle: action.payload,
            };
        case SAVE_ADDRESS:
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    addressDetails: action.payload,
                },
            };
        case FETCH_OPPORTUNITY:
            return {
                ...state,
                loadedOpportunities: action.payload.data,
            };
        case SAVE_OPPORTUNITY:
            return {
                ...state,
                savedOpportunity: { ...state.savedOpportunity, ...action.payload },
            };
        case FETCH_OPPBYNAME:
            return {
                ...state,
                opportunitiesByName: action.payload.data,
            };
        case FETCH_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: action.payload.data,
                assignments: { assignments: [] },
                currentUserData: {
                    ...initialState.currentUserData,
                },
                employee_listing: false,
            };
        case FETCH_MEMBERS_LIST:
            return {
                ...state,
                membersList: action.payload.data,
            };
        case FETCH_USERDATA:
            return {
                ...state,
                isLoading: false,
                userData: action.payload.data,
                result: [],
            };
        case FETCH_TOTAL_ASSIGNMENTS:
            return {
                ...state,
                isLoading: false,
                total: action.payload.data.totalAssignments,
                status: action.payload.status,
            };
        case FETCH_ASSIGNMENTS:
            return {
                ...state,
                assignments: action.payload.data,
                employeeList: [],
                allAudioProcessed: false,
                dashboard_listing: false,
                assignment_loading: false,
                result: [],
            };
        case FETCH_GROUPS_LIST:
            return {
                ...state,
                result: action.payload.data,
            };
        case FETCH_SELECTION_DATA:
            return {
                ...state,
                selectionData: action.payload.data,
            };
        case CHANGE_PRODUCT_ACCESS:
            return {
                ...state,
                employeeList: action.payload,
            };
        case FETCH_PRODUCTS:
            return {
                ...state,
                products: action.payload.data,
            };
        case FETCH_SIM_DETAILS:
            let newData = {};
            if (!action.payload.data.result.sim_characters.length) {
                newData.id = action.payload.data.result.id;
                newData.title = action.payload.data.result.title;
                newData.description = action.payload.data.result.description;
                newData.brief_description = action.payload.data.result.brief_description;
                newData.product_id = action.payload.data.result.product_id;
                newData.admin_note = action.payload.data.result.admin_note;
                newData.sim_type = action.payload.data.result.sim_type;
                newData.auto_populate = action.payload.data.result.auto_populate;
                newData.real_time_feedback = action.payload.data.result.real_time_feedback;
                newData.natural_lenguage = action.payload.data.result.natural_lenguage;
                newData.draft = action.payload.data.result.draft;
                newData.documents = action.payload.data.result.documents.length
                    ? action.payload.data.result.documents : [];
                newData.is_pass_fail_criteria_set = action.payload.data.result.is_pass_fail_criteria_set
                    || false;
                newData.sim_characters = [
                    {
                        name: 'Jessie',
                        voice: 'Kendra',
                        type: 'customer',
                        avatar: 'avatar-13',
                        masked_type: '',
                    },
                    {
                        name: 'You',
                        voice: 'Joanna',
                        type: 'agent',
                        avatar: 'avatar-6',
                        masked_type: '',
                    },
                    {
                        name: 'KJ',
                        voice: 'Joey',
                        type: 'coach',
                        avatar: 'avatar-3',
                        masked_type: '',
                    },
                    {
                        name: 'Jim',
                        voice: 'Matthew',
                        type: 'other',
                        avatar: 'avatar-19',
                        masked_type: '',
                    },
                ];
                newData.levels = [];
            } else {
                newData = action.payload.data.result;
            }
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    ...newData,
                    pass_fail_id: action.payload.data.result.pass_fail_id || {},
                    is_pass_fail_criteria_set:
                        action.payload.data.result.is_pass_fail_criteria_set || false,
                    fetchStatus: action.payload.data.success,
                },
                createKeywords: action.payload.data.result.keywords
                    ? action.payload.data.result.keywords
                    : action.payload.data.result.keywords,
                passFailCriteriaRes: action.payload.data.result.pass_fail_id || {},
            };
        case SET_EDIT_SIM_STEP:
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    activeStep: 2,
                },
            };
        case SET_EDIT_SIM_DETAILS_STEP:
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    activeStep: 0,
                },
                currentSim: {},
            };
        case FETCH_PERMISSIONS_LIST:
            return {
                ...state,
                permissionList: action.payload.data,
                groups: [],
            };
        case SAVE_ELAPSED_TIME: {
            return {
                ...state,
                elapsedTime: action.payload,
            };
        }
        case SAVE_WRAP_SECONDS: {
            return {
                ...state,
                wrapSeconds: action.payload,
            };
        }
        case FETCH_USERS:
            return {
                ...state,
                users: action.payload.data,
            };
        case ADD_ASSIGNMENT:
            return {
                ...state,
                result: action.result,
                assignments: { assignments: [] },
            };
        case ADD_NEW_LEVELS:
            return {
                ...state,
                newSim: {
                    levels: state.newSim.levels,
                    ...action.payload,
                },
            };
        case GENERATE_SIM:
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    levels: [...state.newSim.levels, ...action.payload.data],
                },
            };
        case FETCH_SIM_LEVEL_USER_RESPONSE:
            return {
                ...state,
                isLoading: false,
                userResponse: action.payload.data.sim_level_user_response,
                status: action.payload.status,
            };
        case FETCH_USER_FINAL_SCORE_RESPONSE:
            return {
                ...state,
                userResult: payload,
                currentClip: {},
                isLoading: false,
            };
        case RESET:
            const newObject = {};
            newObject.id = '';
            newObject.sim_characters = [
                {
                    name: 'Jessie',
                    voice: 'Kendra',
                    type: 'customer',
                    avatar: 'avatar-13',
                    masked_type: '',
                },
                {
                    name: 'You',
                    voice: 'Joanna',
                    type: 'agent',
                    avatar: 'avatar-6',
                    masked_type: '',
                },
                {
                    name: 'KJ',
                    voice: 'Joey',
                    type: 'coach',
                    avatar: 'avatar-3',
                    masked_type: '',
                },
                {
                    name: 'Jim',
                    voice: 'Matthew',
                    type: 'other',
                    avatar: 'avatar-19',
                    masked_type: '',
                },
            ];
            newObject.documents = [];
            newObject.levels = [];
            newObject.fetchStatus = null;
            newObject.call_type = 'Physician Referral';
            newObject.captivate_demo_video = '';
            newObject.captivate_learning_video_name = '';
            newObject.tutorial_video_name = '';
            newObject.captivate_folder_name = '';
            newObject.sheet_name = '';
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    ...newObject,
                },
                articles: [],
                data: [],
                deletedSims: [],
                currentSim: {},
                createKeywords: [],
                levelClips: {},
                levelClipsTemp: {},
                assessor_notes: '',
                userResult: {},
                screenRecorderBlob: '',
                assignments: { assignments: [] },
                currentClip: {},
                employee_listing: true,
                captivateWindowRef: {},
                openPassFailModal: false,
                elapsedTime: 0,
                totalSeconds: 0,
                wrapSeconds: 0,
            };
        case SAVE_CASE_VALUES:
            return {
                ...state,
                caseValuesArray: {
                    data: payload,
                    otherCount: action.otherCount,
                    textCount: action.textCount,
                },
                resultScreenInputData: action.resultScreenInputData,
            };
        case SET_PASS_FAIL_CRITERIEA:
            return {
                ...state,
                passFailCriteriaRes: payload,
                newSim: {
                    ...state.newSim,
                    is_pass_fail_criteria_set: true,
                    pass_fail_id: payload,
                },
                openPassFailModal: true,
            };
        case SAVE_USER:
            return {
                ...state,
                result: action.result,
                employeeList: [],
            };
        case FETCH_AUTO_POPULATE:
            return {
                ...state,
                isLoading: false,
                autoPopulate: action.payload,
                status: action.payload.status,
            };
        case FETCH_GROUP_LIST:
            return {
                ...state,
                groups: action.payload.data,
            };
        case FETCH_USER_DATA:
            return {
                ...state,
                employeeList: [],
                isLoading: true,
                currentUserData: action.payload.data,
                assignments: { assignments: [] },
                status: action.payload.status,
                result: [],
            };
        case UPLOAD_LEVELS:
            let newArr = [];
            if (state.newSim.levels[0].scripts.length < 1) {
                newArr = [...action.payload.data.data];
            } else {
                newArr = [...state.newSim.levels, ...action.payload.data.data];
            }
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    levels: [...newArr],
                },
            };
        case NOTES_SAVE:
            return {
                ...state,
                notes: [...action.payload],
            };
        case FETCH_CURRENT_STATUS:
            return {
                ...state,
                currentAssignmentStatus: payload,
                takeSimFromAssignmentSection: true,
        showReviewerButtons: action.isReviewer,
            };
        case DELETE_PREVIOUS_ASSIGNMENT_DETAILS:
            return {
                ...state,
                currentAssignmentStatus: {},
                userResponse: {},
                takeSimFromAssignmentSection: false,
        showReviewerButtons: false,
            };
        case TOGGLE_RESULT_BUTTONS:
            if (payload === true) {
                return {
                    ...state,
                    showResultButtons: payload,
                };
            }
            return {
                ...state,
                showResultButtons: payload,
            };

        case SAVE_SIM_TITLE:
            return {
                ...state,
                isLoading: false,
                simTitle: action.payload,
            };
        case SELECTED_SIM:
            return {
                ...state,
                isLoading: false,
                selectedSim: action.payload,
            };
        case CURRENT_AUDIO_PLAYING:
            return {
                ...state,
                currentAudioPlaying: action.payload,
            };
        case AUDIO_PLAY:
            return {
                ...state,
                audioPlaying: action.payload,
            };
        case ADD_ASSESSOR_NOTES:
            return {
                ...state,
                assessorNotes: action.payload,
            };
        case SHOW_REVIEWER_BUTTON:
            return {
                ...state,
        showReviewerButtons: payload,
                takeSimFromAssignmentSection: false,
            };
        case FETCH_SCORES:
            return {
                ...state,
                scores: action.payload.data,
                employeeList: [],
                assignment_loading: false,
                dashboard_listing: false,
            };
        case SAVE_ASSESSOR_NOTES:
            return {
                ...state,
                assessor_notes: action.payload,
            };
        case ALL_LEVEL_AGENT_AUDIO_PROCESS:
            return {
                ...state,
                allAudioProcessed: payload,
                currentClip: {},
                isLoading: false,
            };
        case ADD_SCREEN_RECORDER_BLOB:
            return {
                ...state,
                screenRecorderBlob: action.payload,
            };
        case RESET_STATS:
            return {
                ...state,
                stats: {},
                status: '',
            };
        case UPDATE_CURRENT_CLIP:
            return {
                ...state,
                currentClip: payload,
                isRestartLevel: false,
            };
        case RESTART_LEVEL_IN_TAKE_SIM:
            return {
                ...state,
                isRestartLevel: true,
            };
        case ENABLE_VIDEO_RECORDING:
            return {
                ...state,
                videoRecordingEnabled: true,
            };
        case RESET_VIDEO_STATS:
            return {
                ...state,
                videoRecordingEnabled: false,
                videoUploadStatus: null,
            };
        case VIDEO_UPLOAD_STATUS:
            return {
                ...state,
                videoUploadStatus: payload.success,
            };
        case FETCH_ASSIGNMENT_COUNTS:
            return {
                ...state,
                assignmentDataCount: payload,
            };
        case FETCH_TRASHED_SIMS:
            return {
                ...state,
                isLoading: true,
                deletedSims: action.payload.data,
                deletedSimsCount: action.payload.data.total,
            };
        case SET_VIDEO_BLOB:
            return {
                ...state,
                videoBlob: payload.get('video_blob'),
            };
        case CLOSE_CAPTIVATE_WINDOW:
            return {
                ...state,
                captivateWindowRef: payload,
            };
        case RESET_PASS_FAIL:
            return {
                ...state,
                newSim: {
                    ...state.newSim,
                    pass_fail_id: {},
                    is_pass_fail_criteria_set: false,
                },
                passFailCriteriaRes: {},
            };
        case RESET_PASSFAIL_MODAL:
            return {
                ...state,
                openPassFailModal: false,
            };
        case SIM_PRODUCT_TYPE:
            return {
                ...state,
                currentSimProductType: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
