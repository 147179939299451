/* eslint-disable no-restricted-globals */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResponsiveDrawer from '../components/common/ResponsiveDrawer.jsx';

const ProtectedRouter = ({
  component: Component, isAuth, title, subtitle, ...rest
}) => {
  let redirectUrl = { ...rest }.location.pathname + { ...rest }.location.search;
  if (window.location.pathname === '/sims/takesim') {
    redirectUrl = '/sims';
  }
  if (window.location.pathname === '/sims/createsim' && !window.location.search) {
    redirectUrl = '/sims';
  }
  localStorage.setItem('redirectUrl', redirectUrl);

  return (isAuth ? (
    <Route
      {...rest}
      render={(props) => (
          <>
            <ResponsiveDrawer
                title={title}
                subtitle={subtitle}
            >
              <Component {...props} />
            </ResponsiveDrawer>
          </>
      )}
    />
  ) : (
    <Redirect to="/login" />
  ));
};

ProtectedRouter.propTypes = {
  isAuth: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  component: PropTypes.elementType,
};
const mapStateToProps = (state) => ({
  isAuth: state.login.isAuth,
});

export default connect(mapStateToProps, null)(ProtectedRouter);
